import { filter, path, find, includes, equals } from 'ramda';

import store from '../index';

import { actions as actionsF } from '../form';

import {
  createNewCommit,
  createNewDocument,
  deleteExistingCommit,
  deleteExistingDocument,
  updateCommitAndClose,
  updateExistingCommit,
  insertMedicationEndReason,
  changeFieldInDocument,
  putNewDocument,
  getDocument,
} from './helpers/apiFetchers';

import { fetchWithOptions } from '../../utility/fetch';
import { getJWTData, parseJWTFromCookie } from '../../utility/jwtAuthTools';
import { makeLog } from '../../utility/logger';
import { INeuroCommit, INeuroDocument } from 'neuro-data-structures';
import {
  filterDiagnoses,
  filterLForceDocs,
  filterMeasurements,
  filterMedications,
  filterUsableDocuments,
} from './helpers/documentFilters';
import { sortAndMergeDocuments } from 'Utility/documentHandling';
import { createAction } from '@reduxjs/toolkit';

/* Action types */
const LOAD = 'neuro-ui/documents/LOAD';
const RECEIVE = 'neuro-ui/documents/RECEIVE';
const CLEAR = 'neuro-ui/documents/CLEAR';
const NEW_DOCUMENT = 'neuro-ui/documents/NEW_DOCUMENT';
const DELETE_DOCUMENT = 'neuro-ui/documents/DELETE_DOCUMENT';
const NEW_COMMIT = 'neuro-ui/documents/NEW_COMMIT';
const UPDATE_COMMIT = 'neuro-ui/documents/UPDATE_COMMIT';
const UPDATE_DOCUMENT = 'neuro-ui/documents/UPDATE_DOCUMENT';
const CANCEL_COMMIT = 'neuro-ui/documents/CANCEL_COMMIT';
const MODIFY_FIELD = 'neuro-ui/documents/MODIFY_FIELD';
const MODIFY_LOCKED_FIELD = 'neuro-ui/documents/MODIFY_LOCKED_FIELD';
const MEDICATION_END_REASON = 'neuro-ui/documents/MEDICATION_END_REASON';
const SET_NEW_DOC = 'neuro-ui/documents/SET_NEW_DOC';
const UNSET_NEW_DOC = 'neuro-ui/documents/UNSET_NEW_DOC';

// Actions for document loading. Called when page is opened or refreshed
const loadAction = createAction(LOAD, () => {
  return {
    payload: null,
  };
});
const receiveAction = createAction(RECEIVE, (documents: INeuroDocument[], sortedAndMergedDocuments: Array<any>) => {
  return {
    payload: { documents: documents, sortedAndMergedDocuments: sortedAndMergedDocuments },
  };
});
const clearAction = createAction(CLEAR, (documents = []) => {
  return {
    payload: { documents },
  };
});

const newDocumentAction = createAction(NEW_DOCUMENT, (data = {}) => {
  return {
    payload: { data },
  };
});
const deleteDocumentAction = createAction(DELETE_DOCUMENT, (documentId: string) => {
  return {
    payload: documentId,
  };
});
const newCommitAction = createAction(NEW_COMMIT, (id: string, data = {}) => {
  return {
    payload: { id, data },
  };
});
const updateCommitAction = createAction(UPDATE_COMMIT, (id: string, cid: string, close: boolean, data = {}) => {
  return {
    payload: { id, cid, close, data },
  };
});
const updateDocumentAction = createAction(UPDATE_DOCUMENT, (document: INeuroDocument) => {
  return {
    payload: document,
  };
});
const cancelCommitAction = createAction(CANCEL_COMMIT, (id: string, cid: string) => {
  return {
    payload: { id, cid },
  };
});

// Change a field in a document
const modifyFieldAction = createAction(MODIFY_FIELD, (id: string, commit: INeuroCommit) => {
  return {
    payload: { id, commit },
  };
});

// Change a field in a locked document
const modifyLockedFieldAction = createAction(MODIFY_LOCKED_FIELD, (id: string, commit: INeuroCommit) => {
  return {
    payload: { id, commit },
  };
});

// Insert medication end reason as a new commit
const medicationEndReasonAction = createAction(MEDICATION_END_REASON, (id: string, commit: INeuroCommit) => {
  return {
    payload: { id, commit },
  };
});

// Set new document being added information so that spinners know to spin inside new and edit buttons
const setNewDocAction = createAction(SET_NEW_DOC, (name: string, id: string) => {
  return {
    payload: { name, id },
  };
});

const unsetNewDocAction = createAction(UNSET_NEW_DOC, () => {
  return {
    payload: null,
  };
});

/**
 * Load all documents from server
 */
const fetchDocumentData =
  (firstFetch = true, medicationFilteringCapability = false) =>
  (dispatch: any): Promise<boolean | undefined> => {
    firstFetch && dispatch(loadAction());

    return fetchWithOptions(`/api/documents`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
      .then(
        (res: Response) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw { status: res.status, fullResponse: res };
          }
        },
        (error: Error) => {
          throw error;
        },
      )
      .then((json) => {
        const jwt = getJWTData();
        if (!jwt) return;

        let documents: INeuroDocument[] = [];

        // Filter usable documents for the current user
        documents = filterUsableDocuments(jwt, json);

        // Filter usable commits/documents
        //documents = filterCommits(jwt, documents);

        // Create an array of sorted and merged documents
        let sortedAndMergedDocuments = sortAndMergeDocuments(documents);

        // Filter diagnosis documents
        documents = filterDiagnoses(documents, sortedAndMergedDocuments);

        // Filter medication docs
        documents = medicationFilteringCapability ? filterMedications(documents, sortedAndMergedDocuments) : documents;

        // Filter measurement docs
        documents = filterMeasurements(documents, sortedAndMergedDocuments);

        // Filter documents from L-Force integration
        documents = filterLForceDocs(documents, sortedAndMergedDocuments);

        // Create a Set of documentIds of filtered documents
        const documentIdsSet = new Set(documents.map((d) => d.documentId));

        // Filter sortedAndMergedDocuments to match filtered documents
        sortedAndMergedDocuments = sortedAndMergedDocuments.filter((d) => documentIdsSet.has(d._id));

        dispatch(actionsF.loadEditingDocuments(documents));
        dispatch(receiveAction(documents, sortedAndMergedDocuments));
        return true;
      })
      .catch((err) => {
        makeLog('Error', err);
        return false;
      });
  };

/** Create a new document (when form handler receives no id ) */
const createDocument =
  (
    name: string,
    // Whether to not to update form editingDocuments with the new document (default false, aka update them). 'true' could be used for sub documents
    noFormUpdate = false,
    initialFormData?: TAnyObject & IControlProps,
  ) =>
  async (dispatch: any): Promise<TCreationPromiseType> => {
    // Create new document (and commit)
    dispatch(setNewDocAction(name, 'noid'));
    const ids = await createNewDocument(name, dispatch, noFormUpdate, initialFormData);
    dispatch(unsetNewDocAction());
    return ids || 'Document creation failed';
  };

/**
 * Put a new document with data
 *
 */
const putDocument =
  (name: string, data: TAnyObject & Partial<IControlProps>) =>
  async (dispatch: any): Promise<TCreationPromiseType> => {
    // Create new document (and commit)
    dispatch(setNewDocAction(name, 'noid'));
    const ids = await putNewDocument(name, dispatch, data);
    dispatch(unsetNewDocAction());
    return ids || 'Document creation failed';
  };

const createCommit =
  (editingDocument: { name: string; id: string }, oldData?: TAnyObject & IControlProps) =>
  async (dispatch: any): Promise<TCreationPromiseType> => {
    const getState = store.getState;
    const allDocuments = (path(['documents', 'documents'], getState?.()) || []) as INeuroDocument[];
    // Check if the document exists
    const curDocument = allDocuments
      ? find((d: INeuroDocument) => d.documentId === editingDocument.id, allDocuments) || null
      : null;
    const openCommit = curDocument
      ? find(
          (c: INeuroCommit) => c.commitDate === null && c.creatorId === getState().session.data?.useruuid,
          curDocument.commits,
        ) || null
      : null;
    if (openCommit) return { _cid: openCommit.commitId };
    else {
      // Create new commit
      dispatch(setNewDocAction(editingDocument.name, editingDocument.id));
      const cid = await createNewCommit(editingDocument, dispatch, oldData);
      dispatch(unsetNewDocAction());
      return cid ? { _cid: cid } : 'Commit creation failed';
    }
  };

/**
 * Update document data
 */
const updateDocumentData =
  (editingDocument: { name: string; id: string }, formData?: TAnyObject & IControlProps) =>
  async (dispatch: any): Promise<TCreationPromiseType> => {
    const getState = store.getState;
    const allDocuments = (path(['documents', 'documents'], getState()) || []) as INeuroDocument[];
    // Check if the document exists
    const curDocument = allDocuments
      ? find((d: INeuroDocument) => d.documentId === editingDocument.id, allDocuments) || null
      : null;
    // Check if an open commit exists
    const openCommit = curDocument
      ? find(
          (c: INeuroCommit) => c.commitDate === null && c.creatorId === getState().session.data?.useruuid,
          curDocument.commits,
        ) || null
      : null;
    if (openCommit) {
      const commitId = openCommit.commitId;
      // Document and commit ok
      // Get formData from props, or from state
      formData = formData
        ? formData
        : ((path(['form', 'formData', editingDocument.id], getState()) || {}) as TAnyObject & IControlProps);
      // Only update if data has changed
      if (!equals(formData, openCommit.data)) {
        const updateMessage = await updateExistingCommit(editingDocument, commitId, dispatch, formData);
        return updateMessage || 'Could not update data';
      }
      return 'No data to update';
    } else return 'No open commits';
  };

/**
 * Finish updating document/commit
 */
const closeCommit =
  (
    editingDocument: { name: string; id: string },
    formData: TAnyObject & IControlProps,
    subDocuments?: { name: string; id: string }[],
  ) =>
  async (dispatch: any): Promise<void> => {
    const getState = store.getState;
    const allDocuments = (path(['documents', 'documents'], getState?.()) || []) as INeuroDocument[];
    // Document currently being processed
    const curDocument = (doc: { name: string; id: string }): INeuroDocument | undefined =>
      allDocuments ? find((d: INeuroDocument) => d.documentId === doc.id, allDocuments) || undefined : undefined;

    const handleClose = async (doc?: INeuroDocument, thisFormData?: TAnyObject & IControlProps): Promise<void> => {
      if (doc) {
        const openCommit = doc
          ? find(
              (c: INeuroCommit) => c.commitDate === null && c.creatorId === getState().session.data?.useruuid,
              doc.commits,
            ) || undefined
          : undefined;
        if (openCommit) {
          const commitId = openCommit.commitId;
          // Document and commit ok
          // Get formData from props, or from state
          formData = thisFormData
            ? thisFormData
            : (path(['form', 'formData', doc.documentId], getState?.()) as TAnyObject & IControlProps);
          await updateCommitAndClose({ name: doc.documentType, id: doc.documentId }, commitId, dispatch, formData);
        }
      }
    };

    // Close main document
    await handleClose(curDocument(editingDocument), formData);

    // Close subDocuments
    if (subDocuments && subDocuments.length > 0) {
      await Promise.all(
        subDocuments.map(async (s) => {
          const thisDoc = curDocument(s);
          // Get data for this document, since formData is not available for sub documents
          const thisData = thisDoc && find((c: INeuroCommit) => c.commitDate === null, thisDoc.commits)?.data;
          await handleClose(thisDoc, thisData);
        }),
      );
    }
  };

/**
 * Find open commit by document Id and delete it if it isn't the only commit, else delete document.
 */
const deleteCommit =
  (editingDocument: { name: string; id: string }, subDocuments?: { name: string; id: string }[]) =>
  async (dispatch: any): Promise<void> => {
    const getState = store.getState;

    let allDocuments = (path(['documents', 'documents'], getState()) || []) as INeuroDocument[];
    let sortedAndMergedDocuments = (path(['documents', 'sortedAndMergedDocuments'], getState()) || []) as Array<
      { [key: string]: any } & IControlProps
    >;

    // Document currently being processed
    const curDocument = (doc: { name: string; id: string }): INeuroDocument | undefined =>
      allDocuments ? find((d: INeuroDocument) => d.documentId === doc.id, allDocuments) || undefined : undefined;

    const handleDelete = async (doc?: INeuroDocument): Promise<void> => {
      // If only one commit exists, then delete the whole document
      if (doc && doc.commits.length <= 1) {
        const reason = { reason: 'Document creation cancelled.' };
        allDocuments = filter((d: INeuroDocument) => d.documentId !== doc.documentId, allDocuments);
        sortedAndMergedDocuments = filter((d) => d._id !== doc.documentId, sortedAndMergedDocuments);
        await deleteExistingDocument(
          { name: doc.documentType, id: doc.documentId },
          dispatch,
          allDocuments,
          sortedAndMergedDocuments,
          reason,
          true, // Force removal of commits
        );
      } else {
        const openCommit = doc
          ? find(
              (c: INeuroCommit) => c.commitDate === null && c.creatorId === getState().session.data?.useruuid,
              doc.commits,
            ) || undefined
          : undefined;
        const commitId = openCommit ? openCommit.commitId : undefined;
        if (doc && commitId) {
          // Delete the open commit
          await deleteExistingCommit({ name: doc.documentType, id: doc.documentId }, commitId, dispatch);
        }
      }
    };

    // Delete main documents open commit
    await handleDelete(curDocument(editingDocument));

    // Delete possible subdocuments open commits
    if (subDocuments && subDocuments.length > 0) {
      await Promise.all(subDocuments.map(async (s) => await handleDelete(curDocument(s))));
    }
  };

/**
 * Delete document based on name and id, supply with reason.
 */
const deleteDocument =
  (
    editingDocument: { name: string; id: string },
    reason: { [key: string]: string | PartialDate },
    subDocs?: Array<{ name: string; id: string } | undefined>, // Sub documents that should be deleted as well
  ) =>
  async (dispatch: any): Promise<void> => {
    const getState = store.getState;

    let allDocuments = (path(['documents', 'documents'], getState?.()) || []) as INeuroDocument[];
    let sortedAndMergedDocuments = (path(['documents', 'sortedAndMergedDocuments'], getState?.()) || []) as Array<
      { [key: string]: any } & IControlProps
    >;
    const curDocument = allDocuments
      ? find((d: INeuroDocument) => d.documentId === editingDocument.id, allDocuments) || null
      : null;
    // Check if document exists
    if (curDocument) {
      // Filter this document id from allDocuments, which is later saved to redux state if delete goes through
      // Filter it here to avoid concurrency problems with subDocs
      allDocuments = filter((d: INeuroDocument) => d.documentId !== curDocument.documentId, allDocuments);
      sortedAndMergedDocuments = filter((d) => d._id !== curDocument.documentId, sortedAndMergedDocuments);
      await deleteExistingDocument(editingDocument, dispatch, allDocuments, sortedAndMergedDocuments, reason, false);
    }
    if (subDocs) {
      // Filter out every sub document from allDocuments at this point, as there has been some problems with concurrency
      allDocuments = filter(
        (d: INeuroDocument) =>
          !includes(
            d.documentId,
            subDocs.map((s) => s?.id),
          ),
        allDocuments,
      );
      subDocs.forEach((d) => {
        if (d?.id && d?.name) {
          deleteExistingDocument(d, dispatch, allDocuments, sortedAndMergedDocuments, reason, true);
        }
      });
    }
  };

/**
 * Insert medication end reason
 */
const medicationEndReason =
  (editingDocument: { name: string; id: string }, endData: IMedicationEndingReason, formData: IMedication) =>
  async (dispatch: any): Promise<void> => {
    const getState = store.getState;

    const allDocuments = (path(['documents', 'documents'], getState?.()) || []) as INeuroDocument[];
    const curDocument = allDocuments
      ? find((d: INeuroDocument) => d.documentId === editingDocument.id, allDocuments) || null
      : null;
    // Check if document exists
    if (curDocument) {
      insertMedicationEndReason(editingDocument, dispatch, endData, formData);
    }
  };

/**
 * Change a field in a (locked) document
 */
const modifyField =
  (
    editingDocument: { name: string; id: string },
    formData: any,
    newData: { [key: string]: any },
    fieldLocked = false, // This only changes which action name redux uses
    collectionInfo?: { id: string; idFieldName: string }, // Currently not possible to use this with multiple fields in new data
  ) =>
  async (dispatch: any): Promise<void> => {
    const getState = store.getState;

    const allDocuments = (path(['documents', 'documents'], getState?.()) || []) as INeuroDocument[];
    const curDocument = allDocuments
      ? find((d: INeuroDocument) => d.documentId === editingDocument.id, allDocuments) || null
      : null;
    // Check if document exists
    if (curDocument) {
      changeFieldInDocument(editingDocument, dispatch, formData, newData, fieldLocked, collectionInfo);
    }
  };

const updateDocumentFromServer =
  (editingDocument: { name: string; id: string }) =>
  async (dispatch: any): Promise<void> => {
    getDocument(editingDocument.name, editingDocument.id).then((res) => {
      if (res) dispatch(updateDocumentAction(res));
    });
  };

export type TCreationPromiseType = { _id?: string; _cid?: string } | string;

/**
 * Actions that do some document data manipulation aside from just updating the redux store
 */
export const actions = {
  fetchDocumentData,
  createDocument,
  putDocument,
  createCommit,
  updateDocumentData,
  updateDocumentFromServer,
  closeCommit,
  deleteCommit,
  deleteDocument,
  medicationEndReason,
  modifyField,
};

/**
 * Actions that exist only to update redux store
 */
export const reduxActions = {
  loadAction,
  receiveAction,
  clearAction,
  newDocumentAction,
  deleteDocumentAction,
  newCommitAction,
  updateCommitAction,
  updateDocumentAction,
  cancelCommitAction,
  modifyFieldAction,
  modifyLockedFieldAction,
  medicationEndReasonAction,
  setNewDocAction,
  unsetNewDocAction,
};
