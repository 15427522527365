import { uniq } from 'ramda';

type TMsTypes = 'RR' | 'SP' | 'PP' | 'cantDefine' | 'unknown';
export type TMsCriteria = 'mcdonald' | 'poser' | 'schumacher' | 'wingerchuk' | 'international';
export type TMsCriteriaRevision = '2001' | '2005' | '2010' | '2017' | 'unknown';

type TPDDiagnosis = 'G20';
type TPSPDiagnosis = 'G23.1';
type TMSADiagnosis = 'G23.2' | 'G23.3' | 'G90.3';
type TCBDDiagnosis = 'G25.9' | 'G31.0';
type TDLBDiagnosis = 'F02.8*G31.8' | 'F00.2*G30.8';
type TFTDDiagnosis = 'F02.0*G31.0';
type TOtherExtraPyramidalDisordersDiagnosis =
  | 'G25'
  | 'G25.0'
  | 'G25.1#'
  | 'G25.2'
  | 'G25.3#'
  | 'G25.4#'
  | 'G25.5'
  | 'G25.6#'
  | 'G25.8';

export const smaDiagnoses: Array<TSMADiagnosis> = ['G12.0', 'G12.1'];
export const dmdDiagnoses: Array<TDMDDiagnosis> = ['G71.06'];

export const pdDiagnoses: Array<TPDDiagnosis> = ['G20'];
export const pspDiagnoses: Array<TPSPDiagnosis> = ['G23.1'];
export const msaDiagnoses: Array<TMSADiagnosis> = ['G23.2', 'G23.3', 'G90.3'];
export const cbdDiagnoses: Array<TCBDDiagnosis> = ['G25.9', 'G31.0'];
export const dlbDiagnoses: Array<TDLBDiagnosis> = ['F00.2*G30.8', 'F02.8*G31.8'];
const ftdDiagnoses: Array<TFTDDiagnosis> = ['F02.0*G31.0'];
const otherExtraPyramidalDisorders: Array<TOtherExtraPyramidalDisordersDiagnosis> = [
  'G25',
  'G25.0',
  'G25.1#',
  'G25.2',
  'G25.3#',
  'G25.4#',
  'G25.5',
  'G25.6#',
  'G25.8',
];

export const parkinsonDiagnoses: Array<TParkinsonDiagnosis> = [
  ...pdDiagnoses,
  ...pspDiagnoses,
  ...msaDiagnoses,
  ...cbdDiagnoses,
  ...dlbDiagnoses,
  ...ftdDiagnoses,
  ...otherExtraPyramidalDisorders,
];
export const msDiagnoses: Array<TMSDiagnosis> = ['G35'];
export const keoDiagnoses: Array<TMSDiagnosis> = ['G36.9', 'G37.9', 'G36.0'];
export const reoDiagnoses: Array<TMSDiagnosis> = ['radiologicallyIsolatedSyndrome'];
export const nmosdDiagnoses: Array<TMSDiagnosis> = ['G36.0'];
export const huntingtonDiagnoses: Array<THuntingtonDiagnosis> = ['G10', 'Z82'];
export const epilepsyDiagnoses: Array<TEpilepsyDiagnosis> = [
  'G40',
  'G40.0',
  'G40.00',
  'G40.01',
  'G40.09',
  'G40.1',
  'G40.10',
  'G40.11',
  'G40.12',
  'G40.19',
  'G40.2',
  'G40.20',
  'G40.21',
  'G40.22',
  'G40.29',
  'G40.3',
  'G40.30',
  'G40.31',
  'G40.33',
  'G40.34',
  'G40.35',
  'G40.36',
  'G40.37',
  'G40.39',
  'G40.4',
  'G40.5',
  'G40.50',
  'G40.51',
  'G40.52#',
  'G40.59',
  'G40.6',
  'G40.7',
  'G40.80',
  'G40.89',
  'G40.9',
  'G41',
  'G41.0',
  'G41.1',
  'G41.2',
  'G41.8',
  'G41.9',
];
export const sleepApneaDiagnoses: Array<TSleepApneaDiagnosis> = [
  'G47.3',
  'J96.1',
  'J96.9',
  'sleepApneaSuspicion',
  'respiratoryFailureSuspicion',
];
export const mgravisDiagnoses: Array<TMgravisDiagnosis> = ['G70.0', 'G73.1'];

/** All diagnoses of all platforms, including _unspecified diagnosis_  */
export const allDiagnoses: Array<TDiagnosis> = uniq([
  ...smaDiagnoses,
  ...dmdDiagnoses,
  ...parkinsonDiagnoses,
  ...msDiagnoses,
  ...keoDiagnoses,
  ...reoDiagnoses,
  ...nmosdDiagnoses,
  ...huntingtonDiagnoses,
  ...epilepsyDiagnoses,
  ...sleepApneaDiagnoses,
  ...mgravisDiagnoses,
  'unspecifiedDiagnosis',
]);
export const msTypes: Array<TMsTypes> = ['RR', 'SP', 'PP', 'cantDefine', 'unknown'];

export type TDocument =
  | IDiagnosis
  | IMsSymptoms
  | IMsMriAndLaboratoryFindings
  | IMSCriteria
  | INMOSDCriteria
  | IClinicalCourse
  | IParkinsonSymptoms
  | IPD
  | IMSA
  | IPSP
  | IDLB
  | ICBD
  | IFTD
  | IPPA
  | IHuntingtonSymptoms
  | IHTT
  | ISeizureType
  | ISyndrome
  | IEtiology
  | IContraIndicationToTreatment;

export const coreClinicalFields = [
  'fluctuatingCognitionWithPronouncedVariationsInAttentionAndAlertness',
  'recurrentVisualHallucinationsThatAreTypicallyWellFormedAndDetailed',
  'remSleepBehaviourDisorderWhichMayPrecedeCognitiveDecline',
  'oneOrMoreSpontaneousCardinalFeaturesOfParkinsonism',
];

export const supportiveClinicalFields = [
  'severeSensitivityToAntipsycoticAgents',
  'posturalInstability',
  'repeatedFalls',
  'syncopeOrOtherTransientEpisodesOfUnresponsiveness',
  'severeAutonomicDysfunction',
  'hypersomnia',
  'hyposmia',
  'hallucinationsInOtherModalities',
  'systematizedDelusions',
  'apathyAnxietyAndDepression',
];

export const indicativeBiomarkerFields = [
  'reducedDopamineTransporterUptakeInBasalGangliaDemonstratedBySPECTorPET',
  'abnormalIodineMIBGMyocardialScintigraphy',
  'polysomnographicConfirmationOfREMsleepWithoutAtonia',
];

export const supportiveBiomarkerFields = [
  'relativePreservationOfMedicalTemporalLobeStructuresOnCTMRIScan',
  'generalizedLowUptakeOnSPECTPETperfusionMetabolismScanWithReducedOccipitalActivity',
  'prominentPosteriorSlowWaveActivityOnEEGwithPeriodicFluctuationsInThePreAlphaThetaRange',
];

export interface IDiagnosisPairs {
  header: JSX.Element | string;
  value: JSX.Element | string | number;
  date?: PartialDate | null;
  key?: string;
}

export type parkinsonCriteriaType = IPD | IMSA | IPSP | IDLB | ICBD | IFTD | IPPA;

export const parkinsonCriteriaTypes = ['pd', 'msa', 'dlb', 'psp', 'cbd', 'ftd', 'ppa'];

export interface IDescription {
  fields: Array<string>;
  section: string;
  marginLeft: number;
}

export interface ISection {
  descriptions: Array<IDescription>;
  section: string;
  marginTop: number;
}

export const descriptionSectionArrayPD = [
  {
    descriptions: [
      {
        fields: ['1', '2', '3'],
        section: 'description.clinicallyEstablished',
        marginLeft: 2,
      },
    ] as Array<IDescription>,
    section: 'clinicallyEstablished',
    marginTop: 1,
  },
  {
    descriptions: [
      {
        fields: ['1', '2'],
        section: 'description.clinicallyProbable',
        marginLeft: 2,
      },
      {
        fields: ['if1RedFlag', 'if2RedFlags', 'noMoreThan2RedFlags'],
        section: 'description.clinicallyProbable',
        marginLeft: 3.7,
      },
    ] as Array<IDescription>,
    section: 'clinicallyProbable',
    marginTop: 1,
  },
] as Array<ISection>;

export const dxCategories: ICategoryOptions[] = [
  {
    title: 'diagnosis.opts.SMA',
    localization: 'diagnosis.sma',
    values: smaDiagnoses,
  },
  {
    title: 'diagnosis.opts.DMD',
    localization: 'diagnosis.dmd',
    values: dmdDiagnoses,
  },
  {
    title: 'diagnosis.opts.PD',
    localization: 'diagnosis.parkinson',
    values: pdDiagnoses,
  },
  {
    title: 'diagnosis.opts.otherExtraPyramidalDisorders',
    localization: 'diagnosis.otherExtraPyramidalDisorders',
    values: otherExtraPyramidalDisorders,
  },
  {
    title: 'diagnosis.opts.PSP',
    localization: 'diagnosis.psp.title',
    values: pspDiagnoses,
  },
  {
    title: 'diagnosis.opts.MSA',
    localization: 'diagnosis.msa.title',
    values: msaDiagnoses,
  },
  {
    title: 'diagnosis.opts.CBD',
    localization: 'diagnosis.cbd.title',
    values: cbdDiagnoses,
  },
  {
    title: 'diagnosis.opts.DLB',
    localization: 'diagnosis.dlb.title',
    values: dlbDiagnoses,
  },
  {
    title: 'diagnosis.opts.FTD',
    localization: 'diagnosis.ftd.titleLong',
    values: ftdDiagnoses,
  },
  {
    title: 'diagnosis.opts.MS',
    localization: 'diagnosis.ms',
    values: msDiagnoses,
  },
  {
    title: 'diagnosis.opts.KEO',
    localization: 'diagnosis.keo',
    values: keoDiagnoses,
  },
  {
    title: 'diagnosis.opts.REO',
    localization: 'diagnosis.reo',
    values: reoDiagnoses,
  },
  {
    title: 'diagnosis.opts.NMOSD',
    localization: 'diagnosis.nmosd',
    values: nmosdDiagnoses,
  },
  {
    title: 'diagnosis.opts.HD',
    localization: 'diagnosis.huntington.title',
    values: huntingtonDiagnoses,
  },
  {
    title: 'diagnosis.opts.EP',
    localization: 'diagnosis.epilepsy.title',
    values: epilepsyDiagnoses,
  },
  {
    title: 'diagnosis.opts.SR',
    localization: 'diagnosis.sleepApnea.title',
    values: sleepApneaDiagnoses,
  },
  {
    title: 'diagnosis.opts.mgravis',
    localization: 'diagnosis.mgravis.title',
    values: mgravisDiagnoses,
  },
  {
    title: 'diagnosis.opts.unspecified',
    localization: 'diagnosis.opts.unspecified',
    values: allDiagnoses.filter((dx) => dx.includes('unspecified')),
  },
];

export const epilepsySyndromesTypes = [
  'temporalLobeEpilepsy',
  'frontalLobeEpilepsy',
  'parietalLobeEpilepsy',
  'occipitalLobeEpilepsy',
  'localUnspecifiedEpilepsy',
  'generalUnspecifiedEpilepsy',
  'neonatalSelfLimitingFamilialEpilepsy',
  'neonatalSelfLimitingNonFamilialEpilepsy',
  'infantileSelfLimitingFamilialEpilepsy',
  'infantileSelfLimitingNonFamilialEpilepsy',
  'geneticEpilepsyWithFebrileConvulsionsAndOtherSeizures',
  'infantileMyoclonicEpilepsy',
  'earlyOnsetInfantileEpilepticEncephalopathy',
  'infantileEpilepsyWithMigratorySeizuresOfLocalOrigin',
  'infantileSpasmSyndrome',
  'dravetSyndrome',
  'selfLimitingEpilepsyWithCentrotemporalSpikes',
  'selfLimitingEpilepsyWithAutonomicSeizures',
  'childhoodOccipitalLobeEpilepsyWithVisualSymptoms',
  'fotosensitiveOccipitalLobeEpilepsy',
  'epilepsyWithEyelidMyoclonus',
  'epilepsyWithMyoclonicAbsenceSeizures',
  'myoclonicAtonicEpilepsy',
  'lennoxGastautSyndrome',
  'developmentalAndEpilepticEncephalopathyWithSleepDischargesActivatingDuringEEG',
  'epilepticSyndromeAssociatedWithFebrileInfection',
  'hemiconvulsionHemiplegiaEpilepsy',
  'childhoodAbsenceEpilepsy',
  'youthAbsenceEpilepsy',
  'youthMyoclonicEpilepsy',
  'generalizedEpilepsyWithOnlyUnconsciousnessSeizures',
  'sleepRelatedHypermotoricEpilepsy',
  'familialLocalizedEpilepsyWithVariableColonies',
  'hearingRelatedEpilepsy',
  'progressiveMyoclonicEpilepsy',
  'reflexEpilepsy',
  'otherKnownSyndrome',
  'epilepsyTypeOrSyndromeUnknown',
];

/** Options for international classification of an epilepsy seizure. */
export const seizureClassifications: NonNullable<ISeizure['classification']>[] = [
  '1.0',
  '1.1',
  '1.2',
  '1.2.1',
  '1.2.2',
  '1.2.3',
  '1.2.4',
  '1.3',
  '1.3.1',
  '1.3.2',
  '1.4',
  '1.5',
  '1.6',
  '1.7',
  '2.0',
  '2.1',
  '2.1.1',
  '2.1.1.1',
  '2.1.1.2',
  '2.1.1.3',
  '2.1.1.4',
  '2.1.1.5',
  '2.1.1.6',
  '2.1.1.7',
  '2.1.2',
  '2.1.2.1',
  '2.1.2.2',
  '2.1.2.3',
  '2.1.2.4',
  '2.1.2.5',
  '2.2',
  '2.2.1',
  '2.2.1.1',
  '2.2.1.2',
  '2.2.1.3',
  '2.2.1.4',
  '2.2.1.5',
  '2.2.2',
  '2.2.2.1',
  '2.2.2.2',
  '2.2.2.3',
  '2.2.2.4',
  '2.2.2.5',
  '2.3',
  '3.0',
  '3.0.1',
  '3.0.2',
  '3.0.3',
  '3.0.4',
  '4.0',
];

/*
export const seizureClassifications = [
  '1.0 Yleistynyt kohtaus, tarkemmin luokittelematon',
  '1.1 Toonis-klooninen kohtaus',
  '1.2 Poissaolokohtaus',
  '1.2.1 Tyypillinen poissaolokohtaus',
  '1.2.2 Epätyypillinen poissaolokohtaus',
  '1.2.3 Myoklooninen poissaolokohtaus',
  '1.2.4 Poissaolokohtaus ja silmäluomimyoklonia',
  '1.3 Myoklooninen kohtaus',
  '1.3.1 Myokloonis-atooninen',
  '1.3.2 Myokloonis-toonis-klooninen',
  '1.4 Klooninen kohtaus',
  '1.5 Tooninen kohtaus',
  '1.6 Atooninen kohtaus',
  '1.7 Epileptiset spasmit',
  '2.0 Paikallisalkuinen kohtaus, tarkemmin luokittelematon',
  '2.1. Paikallisalkuinen kohtaus ilman tajunnanhämärtymistä',
  '2.1.1 Motorisin oirein',
  '2.1.1.1 Automatismit',
  '2.1.1.2 Atooninen',
  '2.1.1.3 Klooninen',
  '2.1.1.4 Epileptiset spasmit',
  '2.1.1.5 Hyperkineettinen',
  '2.1.1.6 Myoklooninen',
  '2.1.1.7 Tooninen',
  '2.1.2 Muilla kuin motorisilla oireilla',
  '2.1.2.1 Autonomiset oireet',
  '2.1.2.2 Toiminnan pysähtyminen',
  '2.1.2.3 Kognitiiviset oireet',
  '2.1.2.4 Emotionaaliset oireet',
  '2.1.2.5 Aistioireet',
  '2.2. Paikallisalkuinen tajunnanhämärtymiskohtaus',
  '2.2.1 Motorisin oirein',
  '2.2.1.1 Automatismit',
  '2.2.1.2 Klooninen',
  '2.2.1.3 Hyperkineettinen',
  '2.2.1.4 Myoklooninen',
  '2.2.1.5 Tooninen',
  '2.2.2 Muilla kuin motorisilla oireilla',
  '2.2.2.1 Autonomiset oireet',
  '2.2.2.2 Toiminnan pysähtyminen',
  '2.2.2.3 Kognitiiviset oireet',
  '2.2.2.4 Emotionaaliset oireet',
  '2.2.2.5 Aistioireet',
  '2.3 Paikallisalkuinen tajuttomuuskouristuskohtaus',
  '3.0 Ei tietoa, onko paikallisalkuinen vai yleistynyt',
  '3.0.1 Toonis-klooninen',
  '3.0.2 Epileptiset spasmit',
  '3.0.3 Muilla motorisilla oireilla',
  '3.0.4 Muilla kuin motorisilla oireilla',
  '4.0 Kohtausta ei voida luokitella olemassa olevien tietojen perusteella',
] as const;
*/

export const FTDFormContent: {
  [key: string]: { subHeader?: string; criteria?: Array<string>; result?: Array<string> };
} = {
  coreCriteria: { criteria: ['progressiveBehavioralChangeOrImpairedProcessingOfInformation'] },
  possibleFTD: {
    subHeader: 'subHeader',
    criteria: [
      'reductionOfNaturalInhibitionInEarlyStagesOfDisease',
      'apathyOrIntroversion',
      'lossOfExperienceOfSympathyOrEmpathy',
      'repetitiveStereotypicalOrCompulsiveBehavior',
      'hyperoralityOrChangesInDiet',
      'erpDisordersCombinedWithRelativelyWellPreservedMemoryAndVisuospatialFunction',
    ],
    result: ['coreCriteriaAndCriteriaForPossibleFTDFulfilled'],
  },
  probableFTD: {
    subHeader: 'subHeader',
    criteria: [
      'theCriteriaForPossibleFTDInSectionIIAreMet',
      'significantImpairmentOfPerformance',
      'atrophyOfTheFrontalOrTemporalLobeOrDecreaseInCerebralCirculationOrMetabolismInMriCtPetOrSpet',
    ],
    result: ['coreCriteriaAndCriteriaForProbableFTDFulfilled'],
  },
  certainFTD: {
    subHeader: 'subHeader',
    criteria: [
      'criteriaForPossibleOrProbableFTDFulfilled',
      'neuropathologicallyConfirmedFTD',
      'verifiedPatogenicMutation',
    ],
    result: ['coreCriteriaAndCriteriaForCertainFTDFulfilled'],
  },
  exclusionCriteria: {
    criteria: [
      'overallSymptomsAreBetterExplainedByAnotherNonDegenerativeOrMedicalReason',
      'behavioralSymptomsAreBetterExplainedByPsychiatricIllness',
      'biomarkersStronglySupportAlzheimersDiseaseOrOtherNeurodegenerativeProcess',
    ],
    result: ['exclusionCriteriaForFTDFulfilled'],
  },
  summary: {
    subHeader: 'subHeader',
    result: [
      'criteriaForCertainFTDFulfilled',
      'criteriaForProbableFTDFulfilled',
      'criteriaForPossibleFTDFulfilled',
      'coreCriteriaForFTDFulfilled',
      'exclusionCriteriaForFTDFulfilled',
    ],
  },
};

export const PPAFormContent: {
  [key: string]: {
    [key: string]: {
      [key: string]: string | Array<string>;
    };
  };
} = {
  general: {
    theFollowingThreeCharacteristicsShouldBePresentInTheSyndrome: {
      criteria: [
        'linguisticDifficultyIsAKeySymptom',
        'linguisticDifficultyIsTheMostImportantFactorInMakingEverydayActivitiesMoreDifficult',
        'aphasiaShouldBeTheStrongestTraitDuringEarlyStagesOfDiseaseAndDiseaseProgression',
      ],
      result: ['charactersticsOfSectionIArePresentInTheSyndrome'],
    },
    theFollowingFourCharacteristicsShouldNotBePresentInTheSyndrome: {
      criteria: [
        'otherIllnessExplainsTheSymptomsBetter',
        'psychiatricIllnessExplainsTheSymptomsBetter',
        'deteriorationOfEventMemoryProblemsWithVisualMemoryOrVisualPerceptionAreKeySymptoms',
        'changeInBehaviorIsTheStrongestSymptomInEarlyStages',
      ],
      result: ['charactersticsOfSectionIIAreNotPresentInTheSyndrome', 'generalCriteriaFulfilled'],
    },
  },
  nonfluentVariantPPA: {
    clinicalDiagnosis: {
      subHeader: 'subHeader',
      criteria: [
        'grammarErrorsOccurInLanguageProduction',
        'laboriousIntermittentSpeechWithSoundErrorsAndWordDistortionsSpeechApraxia',
      ],
      subHeader2: 'subHeader2',
      criteria2: [
        'understandingGrammaticallyComplexSentencesHasBecomeDifficult',
        'preservedUnderstandingOfIndividualWords',
        'preservedAbilityToRecognizeFacesAndObjects',
      ],
      result: ['criteriaForClinicalDiagnosisForNfvPPAFulfilled'],
    },
    diagnosisSupportedByImagingStudies: {
      subHeader: 'subHeader',
      criteria: ['criteriaForClinicalDiagnosisForNfvPPAFulfilled'],
      subHeader2: 'subHeader2',
      criteria2: [
        'atrophyOfTheLeftPosteriorFrontoinsularCortexInTheMK',
        'hypoperfusionOrHypometabolismOfTheLeftPosteriorFrontoinsularCortexInPETOrSPET',
      ],
      result: ['criteriaForDiagnosisSupportedByImagingStudiesForNfvPPAFulfilled'],
    },
    neuropathologicallyConfirmedDiagnosis: {
      subHeader: 'subHeader',
      criteria: [
        'criteriaForClinicalDiagnosisForNfvPPAFulfilled',
        'confirmedNeuropathology',
        'knownGeneMutationProven',
      ],
      result: ['criteriaForNeuropathologicallyConfirmedDiagnosisForNfvPPAFulfilled'],
    },
  },
  semanticDementia: {
    clinicalDiagnosis: {
      subHeader: 'subHeader',
      criteria: ['clearDifficultiesInNamingThings', 'difficultyUnderstandingWords'],
      subHeader2: 'subHeader2',
      criteria2: [
        'impairedFaceAndObjectRecognition',
        'difficultyReadingAndWriting',
        'preservedRepetition',
        'preservedSpeechOutputGrammarAndMotorSkills',
      ],
      result: ['criteriaForClinicalDiagnosisForSemanticDementiaFulfilled'],
    },
    diagnosisSupportedByImagingStudies: {
      subHeader: 'subHeader',
      criteria: ['criteriaForClinicalDiagnosisForSemanticDementiaFulfilled'],
      subHeader2: 'subHeader2',
      criteria2: ['atrophyOfTheAnteriorTemporalLobe', 'anteriorTemporalHypoperfusionOrHypometabolismInSPETOrPET'],
      result: ['criteriaForDiagnosisSupportedByImagingStudiesForSemanticDementiaFulfilled'],
    },
    neuropathologicallyConfirmedDiagnosis: {
      subHeader: 'subHeader',
      criteria: [
        'criteriaForClinicalDiagnosisForSemanticDementiaFulfilled',
        'confirmedNeuropathology',
        'knownGeneMutationProven',
      ],
      result: ['criteriaForNeuropathologicallyConfirmedDiagnosisForSemanticDementiaFulfilled'],
    },
  },
  logopenicProgressiveAphasia: {
    clinicalDiagnosis: {
      subHeader: 'subHeader',
      criteria: ['difficultyFindingWordsInSpontaneousSpeechAndNaming', 'difficultyRepeatingASentenceOrPhrase'],
      subHeader2: 'subHeader2',
      criteria2: [
        'soundErrorsInSpontaneousSpeechAndNaming',
        'understandingOfIndividualWordsAndTheAbilityToRecognizeFacesAndObjectsRemain',
        'motoricSpeechIsPreserved',
        'noSignificantGrammaticalErrors',
      ],
      result: ['criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled'],
    },
    diagnosisSupportedByImagingStudies: {
      subHeader: 'subHeader',
      criteria: ['criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled'],
      subHeader2: 'subHeader2',
      criteria2: [
        'atrophyOfTheLeftPosteriorSylviusGrooveRegionOrParietalCortexInMK',
        'hypoperfusionOrHypometabolismOfTheLeftPosteriorSylviusGrooveRegionOrParietalCortexInPETOrSPET',
      ],
      result: ['criteriaForDiagnosisSupportedByImagingStudiesForLogopenicProgressiveAphasiaFulfilled'],
    },
    neuropathologicallyConfirmedDiagnosis: {
      subHeader: 'subHeader',
      criteria: [
        'criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled',
        'histopathologicalEvidenceOfSpecificNeurodegenerativeDisease',
        'knownGeneMutationProven',
      ],
      result: ['criteriaForNeuropathologicallyConfirmedDiagnosisForLogopenicProgressiveAphasiaFulfilled'],
    },
    summary: {
      subHeader: 'subHeader',
      result: [
        'criteriaForDiagnosisSupportedByImagingStudiesForNfvPPAFulfilled',
        'criteriaForNeuropathologicallyConfirmedDiagnosisForNfvPPAFulfilled',
      ],
      subHeader2: 'subHeader2',
      result2: [
        'criteriaForDiagnosisSupportedByImagingStudiesForSemanticDementiaFulfilled',
        'criteriaForNeuropathologicallyConfirmedDiagnosisForSemanticDementiaFulfilled',
      ],
      subHeader3: 'subHeader3',
      result3: [
        'criteriaForDiagnosisSupportedByImagingStudiesForLogopenicProgressiveAphasiaFulfilled',
        'criteriaForNeuropathologicallyConfirmedDiagnosisForLogopenicProgressiveAphasiaFulfilled',
      ],
    },
  },
};
