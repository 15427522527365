import { isPartialDate } from 'neuro-utils';
import { path } from 'ramda';
import * as React from 'react';

import { IAddon, IData, IDataPoint, IEvent, IItem, TDescriptionTable } from 'Components/sq-graphics';
import { steps } from 'Routes/FunctionalPerformanceMgravis/Document/Form/mgc';
import { isMGADLComplete, isMGCComplete, mgadlScore, mgcScore } from 'Routes/FunctionalPerformanceMgravis/utils';
import { sortDocuments } from 'Utility/randomUtil';
import { IPassQuestion, passQuestion } from 'neuro-schemas';

const changeToLastMGC = (thisDoc: IMGC, lastDoc: IMGC) => {
  const changes: Array<{ step: string; thisData: number | string; lastData: number | string }> = [];
  if (!isMGCComplete(thisDoc) || !isMGCComplete(lastDoc)) return changes;
  steps.forEach((step) => {
    const thisData = path([step], thisDoc) as number | string;
    const lastData = path([step], lastDoc) as number | string;
    if (thisData !== lastData) changes.push({ step, thisData, lastData });
  });
  return changes;
};

export const convertMGCToGraph = (
  docs: Array<IMGC>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];

  const sortedAndFilteredDocs = docs
    .filter((d) => {
      const isComplete = isMGCComplete(d);
      return (isComplete || d.manualScore || d.manualScore === 0) && isPartialDate(d.date);
    })
    .sort((d1, d2) => sortDocuments([{ type: 'date', sortField: 'date' }])(d1, d2));

  sortedAndFilteredDocs.forEach((d, i) => {
    d.date &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: isMGCComplete(d) ? mgcScore(d) : d.manualScore ?? 0,
        id: 'mgc',
        description:
          sortedAndFilteredDocs[i + 1] && changeToLastMGC(d, sortedAndFilteredDocs[i + 1]).length > 0 ? (
            <div>
              {changeToLastMGC(d, sortedAndFilteredDocs[i + 1]).map((change) => (
                <div key={change.step}>
                  {`${fm('functionalPerformanceMgravis.labels.mgc.' + change.step)}: `}{' '}
                  <span style={{ fontWeight: 600 }}>{change.lastData}</span>
                  {' -> '}
                  <span style={{ fontWeight: 600 }}>{change.thisData}</span>
                </div>
              ))}
            </div>
          ) : !isMGCComplete(d) && (d.manualScore || d.manualScore === 0) ? (
            fm('functionalPerformanceMgravis.manuallyEnteredScore')
          ) : undefined,
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'mgc',
      legend: fm('functionalPerformanceMgravis.mgcShort'),
      type: 'lineGraph',
    },
  ];
};

const changeToLastMGADL = (thisDoc: IMGADL, lastDoc: IMGADL) => {
  const changes: Array<{ step: string; thisData: number; lastData: number }> = [];
  if (!isMGADLComplete(thisDoc) || !isMGADLComplete(lastDoc)) return changes;
  steps.forEach((step) => {
    const thisData = path([step], thisDoc) as number;
    const lastData = path([step], lastDoc) as number;
    if (thisData !== lastData) changes.push({ step, thisData, lastData });
  });
  return changes;
};

export const convertMGADLToGraph = (
  docs: Array<IMGADL>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];

  const sortedAndFilteredDocs = docs
    .filter((d) => {
      const isComplete = isMGADLComplete(d);
      return (isComplete || d.manualScore || d.manualScore === 0) && isPartialDate(d.date);
    })
    .sort((d1, d2) => sortDocuments([{ type: 'date', sortField: 'date' }])(d1, d2));

  sortedAndFilteredDocs.forEach((d, i) => {
    d.date &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: isMGADLComplete(d) ? mgadlScore(d) : d.manualScore ?? 0,
        id: 'mgadl',
        description:
          sortedAndFilteredDocs[i + 1] && changeToLastMGADL(d, sortedAndFilteredDocs[i + 1]).length > 0 ? (
            <div>
              {changeToLastMGADL(d, sortedAndFilteredDocs[i + 1]).map((change) => (
                <div key={change.step}>
                  {`${fm('functionalPerformanceMgravis.labels.mgadl.' + change.step)}: `}{' '}
                  <span style={{ fontWeight: 600 }}>{change.lastData}</span>
                  {' -> '}
                  <span style={{ fontWeight: 600 }}>{change.thisData}</span>
                </div>
              ))}
            </div>
          ) : !isMGADLComplete(d) && (d.manualScore || d.manualScore === 0) ? (
            fm('functionalPerformanceMgravis.manuallyEnteredScore')
          ) : undefined,
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'mgadl',
      legend: fm('functionalPerformanceMgravis.mgadlShort'),
      type: 'lineGraph',
    },
  ];
};

export const convertSymptomsToTimeline = (
  docs: Array<IMGravisSymptoms>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (name: string, values?: Record<string, any>) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;

    const events: IEvent[] = [];
    doc.date &&
      events.push({
        date: dateFromPartialUpdateTimeframe(doc.date, undefined, true),
        eventType: 'themedDiamondWithText',
        title: fm('diagnosis.mgravis.symptoms.title'),
        description: [
          {
            title: fm('diagnosis.mgravis.symptoms.location'),
            values: Array.isArray(doc.symptomLocation) && doc.symptomLocation.length > 0 && (
              <div>
                {doc.symptomLocation?.map((loc) => (
                  <div style={{ width: '100%', display: 'block' }} key={loc}>
                    {fm('diagnosis.mgravis.symptoms.' + loc)}
                  </div>
                ))}
              </div>
            ),
          },
        ],
        priority: 'high',
        iconText: fm('diagnosis.mgravis.symptoms.year', { year: doc.age }),
      });
    addons.push({
      id: 'earlySymptoms',
      title: fm('diagnosis.mgravis.symptoms.title').toLocaleUpperCase(),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertPassQuestionToTimeline = (
  docs: Array<IPassQuestion & IControlProps>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  locale: Locale,
  fm: (name: string, values?: Record<string, any>) => string,
): Array<IAddon> | undefined => {
  const locales = passQuestion.localizations[locale];

  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  const events: Array<IEvent> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    doc.satisfiedWithCurrentSituation &&
      doc.date &&
      events.push({
        date: dateFromPartialUpdateTimeFrame(doc.date),
        eventType: 'activity',
        priority: doc.satisfiedWithCurrentSituation === 'no' ? 'high' : 'low',
        title: fm('myService.mgravis.opts.passQuestion.' + doc.satisfiedWithCurrentSituation),
      });
  });
  addons.push({
    id: 'passQuestion',
    title: locales['title'],
    titleDescription: undefined,
    items: [],
    events: events,
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertHospitalizationToTimelineMgravis = (
  docs: Array<IHospitalization>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    if (!doc.department) return;
    const items: IItem[] = [];
    doc.date &&
      items.push({
        start: dateFromPartialUpdateTimeFrame(doc.date),
        end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeFrame(doc.endDate) : undefined,
        title: fm(`comorbidity.mgravis.filter.${doc.department}`),
        themeId:
          doc.department === 'intensiveCare' ? 'flirt' : doc.department === 'hospitalWard' ? 'warningLight' : undefined,
      });
    addons.push({
      id: doc.department,
      title: fm(`comorbidity.mgravis.filter.${doc.department}`),
      titleDescription: undefined,
      items: items,
      themeId:
        doc.department === 'intensiveCare'
          ? 'intensiveCareHospitalization'
          : doc.department === 'hospitalWard'
            ? 'hospitalWardHospitalization'
            : undefined,
    });
  });

  return addons.length > 0 ? addons : undefined;
};

export const convertProcedureToTimeline = (
  docs: Array<IProcedure>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return;
  const thymectomyEvents: IEvent[] = [];
  const plasmaChangeEvents: IEvent[] = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    if (!doc.code) return;
    if (doc.code.code === '19647005') {
      doc.date &&
        plasmaChangeEvents.push({
          date: dateFromPartialUpdateTimeFrame(doc.date),
          title: fm('procedure.plasmaChange'),
          eventType: 'treatment',
        });
    } else if (doc.code.code === '399750009') {
      doc.date &&
        thymectomyEvents.push({
          date: dateFromPartialUpdateTimeFrame(doc.date),
          title: fm('procedure.thymectomy'),
          eventType: 'treatment',
        });
    }
  });
  if (thymectomyEvents.length === 0 && plasmaChangeEvents.length === 0) return;

  return [
    {
      id: '19647005',
      title: fm('procedure.plasmaChange'),
      events: plasmaChangeEvents,
    },
    {
      id: '399750009',
      title: fm('procedure.thymectomy'),
      events: thymectomyEvents,
    },
  ];
};

const getCnpExamDesc = (doc: IDiagnosticExamination, fm: (id: string) => string): TDescriptionTable => {
  return [
    {
      title: fm('clpAndCnpTests.mgravis.findingType'),
      values: fm(`clpAndCnpTests.mgravis.opts.${doc.finding?.type}`),
      condition: !!doc?.finding?.type,
    },
    {
      title: fm('clpAndCnpTests.mgravis.abnormalDetails'),
      values: doc?.finding?.abnormalDetails,
      condition: !!doc?.finding?.abnormalDetails,
    },
    {
      title: fm('clpAndCnpTests.mgravis.supportDiagnosis'),
      values: fm(`general.${!!doc?.finding?.supportDiagnosis}`),
      condition: doc?.finding?.type === 'abnormal',
    },
  ];
};

export const convertCnpExamsToTimeline = (
  docs: Array<IDiagnosticExamination>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return;
  const codes = ['XA402', '252748005', '252756008'];
  const enmgEvents: IEvent[] = [];
  const repeatStimulationEvents: IEvent[] = [];
  const singleFiberEMGEvents: IEvent[] = [];
  const events: Record<string, IEvent[]> = {
    XA402: enmgEvents,
    '252748005': repeatStimulationEvents,
    '252756008': singleFiberEMGEvents,
  };
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!d.code) return;
    if (!codes.includes(d.code.code)) return;
    d.date &&
      events[d.code.code].push({
        date: dateFromPartialUpdateTimeFrame(d.date),
        title: fm(`clpAndCnpTests.mgravis.${d.code.code}`),
        description: getCnpExamDesc(d, fm),
        eventType: 'treatment',
      });
  });
  if (enmgEvents.length === 0 && repeatStimulationEvents.length === 0 && singleFiberEMGEvents.length === 0) return;
  return codes
    .map((c) => ({
      id: c,
      title: fm(`clpAndCnpTests.mgravis.${c}`),
      events: events[c],
    }))
    .filter((addon) => addon.events.length !== 0);
};

export const convertDiagnosticTestsToTimeline = (
  docs: Array<IDiagnosticExamination>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return;
  const codes = ['1004087002', '1004088007'];
  const neostigmineTestEvents: IEvent[] = [];
  const iceCubeTestEvents: IEvent[] = [];
  const events: Record<string, IEvent[]> = {
    '1004087002': neostigmineTestEvents,
    '1004088007': iceCubeTestEvents,
  };
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    if (!d.code) return;
    if (!codes.includes(d.code.code)) return;
    d.date &&
      events[d.code.code].push({
        date: dateFromPartialUpdateTimeFrame(d.date),
        title: fm(`diagnosticTests.${d.code.code}`),
        description: [
          {
            title: fm('diagnosticTests.interpretation'),
            values: fm(`diagnosticTests.opts.${d?.finding?.supportDiagnosis ? 'support' : 'noSupport'}`),
            condition: typeof d?.finding?.supportDiagnosis === 'boolean',
          },
        ],
        eventType: 'treatment',
      });
  });
  if (neostigmineTestEvents.length === 0 && iceCubeTestEvents.length === 0) return;
  return codes
    .map((c) => ({
      id: c,
      title: fm(`diagnosticTests.${c}`),
      events: events[c],
    }))
    .filter((addon) => addon.events.length !== 0);
};
