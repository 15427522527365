import { fetchWithOptions } from '../../utility/fetch';
import { parseJWTFromCookie } from '../../utility/jwtAuthTools';
import { makeLog } from '../../utility/logger';
import { ICompactVaultUser, IVaultOrganization } from 'neuro-data-structures';

/**
 * Sync patient medications from med integrations api
 * @returns {boolean} Whether sync was successful
 */
export const medicationSync = async (system: 'miranda' | 'apotti' | 'lifecare'): Promise<boolean> => {
  const syncUrlMap: { [key in typeof system]: string } = {
    miranda: '/api/requestMirandaUpdate',
    apotti: '/api/apottiMedication/v1/',
    lifecare: '/api/v1/lifecare/medication',
  };

  return await fetchWithOptions(syncUrlMap[system], { neurojwt: parseJWTFromCookie() }, { method: 'get' })
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
      throw { status: res.status, fullResponse: res };
    })
    .catch((err) => {
      makeLog('Warning', { name: 'Medication sync ' + system, message: 'Sync not successful' }, err);
      return false;
    });
};

/**
 * Get care table data from Uranus
 * @returns {boolean} Whether sync was successful
 */
export const caretableSync = async (): Promise<boolean> => {
  return await fetchWithOptions(`/api/v1/caretable/uranus`, { neurojwt: parseJWTFromCookie() }, { method: 'get' })
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
      throw { status: res.status, fullResponse: res };
    })
    .catch((err) => {
      makeLog('Warning', { name: 'Care table sync', message: 'Sync not successful' }, err);
      return false;
    });
};

/**
 * Get laboratory data from lifecare
 * @returns {boolean} Whether sync was successful
 */
export const lifecareLaboratorySync = async (): Promise<boolean> => {
  return await fetchWithOptions(`/api/v1/lifecare/laboratory`, { neurojwt: parseJWTFromCookie() }, { method: 'get' })
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
      throw { status: res.status, fullResponse: res };
    })
    .catch((err) => {
      makeLog('Warning', { name: 'Lifecare laboratory sync', message: 'Sync not successful' }, err);
      return false;
    });
};

/**
 * Trigger synchronization of Fimlab lab data for currently active patient
 * @returns {Promise<boolean>} Resolve with success status
 */
export const fimlabSync = async (): Promise<boolean> => {
  try {
    const response = await fetchWithOptions(
      '/api/fimlabsync/v1/',
      { neurojwt: parseJWTFromCookie() },
      { method: 'get' },
    );

    if (response.status !== 200) {
      throw { status: response.status, rawResponse: response };
    }

    return true;
  } catch (error) {
    makeLog('Warning', { name: 'fimlab-sync', message: 'Fimlab sync failed' }, error);
    return false;
  }
};

/**
 * Get imaging examination data from L-Force
 * @returns {boolean} Whether sync was successful
 */
export const imagingSync = async (): Promise<boolean> => {
  const searchDate = '2020-01-01'; // The date must be formatted as yyyy-mm-dd.

  return await fetchWithOptions(
    `/api/v1/lforce/examination/${searchDate}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'get' },
  )
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
      throw { status: res.status, fullResponse: res };
    })
    .catch((err) => {
      makeLog('Warning', { name: 'Imaging sync', message: 'Sync not successful' }, err);
      return false;
    });
};

/**
 * Fetch organization data based on JWT
 * @returns {Promise<IVaultOrganization | null>} Org data or null
 */
export const fetchOrganizationData = async (): Promise<IVaultOrganization | null> => {
  const neurojwt = parseJWTFromCookie();
  if (!neurojwt) return null;
  try {
    const response = await fetchWithOptions(
      '/api/vault/v2/organizations/',
      { neurojwt, requestorigin: 'neuro-ui' },
      { method: 'GET' },
    );
    if (response.status === 200) {
      return response.json();
    }
    return null;
  } catch (error) {
    makeLog('Error', { name: 'Organization data fetch', message: 'Failed to fetch organization data.' }, error);
    return null;
  }
};

/**
 * Fetch all organizations in the current group
 * @param {string} groupName - Group name
 * @returns {Promise<Array<Pick<IVaultOrganization, 'orgId'|'displayName'>>|null>} Org data array or null
 */
export const fetchOrganizationGroup = async (
  groupName: string,
): Promise<Array<Pick<IVaultOrganization, 'orgId' | 'displayName'>> | null> => {
  const neurojwt = parseJWTFromCookie();
  if (!neurojwt) return null;
  try {
    const response = await fetchWithOptions(
      `/api/vault/v2/organizations?groupName=${groupName}`,
      { neurojwt, requestorigin: 'neuro-ui' },
      { method: 'GET' },
    );
    if (response.status === 200) {
      return response.json();
    }
    return null;
  } catch (error) {
    makeLog('Error', { name: 'Organization group fetch', message: 'Failed to fetch organization group.' }, error);
    return null;
  }
};

/**
 * Fetch preferred platforms for given patient
 * @returns {Promise<Array<Platform> | null>} Platforms array or null
 */
export const fetchPreferredPlatforms = async (): Promise<Array<Platform> | null> => {
  return fetchWithOptions(
    '/api/recommend/platform',
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'GET',
    },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw { status: res.status, fullResponse: res };
    })
    .then((data) => data)
    .catch((err: Error) => {
      makeLog('Error', { name: 'Preferred platforms fetch', message: 'Error while fetching data.' }, err);
      return null;
    });
};

// Returns users for the given organization
export const fetchOrgUsers = async (orgid: string): Promise<Array<ICompactVaultUser> | null> => {
  return fetchWithOptions(
    '/api/vault/v2/userless/' + orgid,
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'GET',
    },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw { status: res.status, fullResponse: res };
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'Users fetch', message: 'Error while fetching users.' }, err);
      return null;
    });
};

// Get statistics token for platform
export const getStatisticsTokenForPlatform = async (platform: string): Promise<ITokenData | null> => {
  return fetchWithOptions(`/api/powerbi/${platform}`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res) => {
      if (res.ok) return res.json();
      else throw { message: 'Power BI token fetching failed', stack: res };
    })
    .catch((error) => {
      makeLog('Error', error);
      return null;
    });
};
