import { path, pick, sum, values, omit, uniq } from 'ramda';
import { controlProps } from '../../../utility/documentHandling';
import { getPatientAgeInMonths } from '../../../utility/patientInfo';
import { steps as mfmSteps, disabledSteps as disabledMfmSteps } from '../Document/Form/mfmDisabledSettings';

const exists = (value: number | undefined): boolean => (value || value === 0 ? true : false);

/**
 * Checks if all eight HINE MM sections are filled
 * @param  {IHINEMM[]} doc - HINEMM Document
 * @returns {boolean} True if all sections are filled, false if not
 */
export const isHineMMComplete = (doc: IHINEMM): boolean =>
  Object.keys(omit(['date', ...controlProps], doc)).filter((key) => {
    return exists(path([key], doc));
  }).length === 8;

/**
 * Counts HINEMM total score from score values
 * @param  {IHINEMM[]} doc - HINEMM Document
 * @returns {number|'notCounted'} HINEMM Total Score (tm) (0-26)
 */
export const hineMMScore = (doc: IHINEMM): number | 'notCounted' => {
  if (!isHineMMComplete(doc)) {
    return 'notCounted';
  }
  let score = 0;
  Object.keys(omit(['date', ...controlProps], doc)).forEach((key) => {
    if (path([key], doc) && typeof path([key], doc) === 'number') {
      score += path([key], doc) as number;
    }
  });
  return score;
};

/**
 * Count score from a RULM document
 * @param {object} doc - RULM document where the score is counted from
 * @param {string} hand - Hand the score will be counted with
 * @returns {number|'notCounted'} Score
 */
export const rulmScore = (doc?: { [key: string]: any }, hand?: string, notManual?: string): number | 'notCounted' => {
  // Number of fields that are tested with both hands seperately
  const fieldCount = 16;

  if (hand !== 'Left' && hand !== 'Right') return 'notCounted';

  // When document is empty return 'notCounted'
  if (!doc) return 'notCounted';

  // Check the hand and return the related manually input value
  const returnManualScore = (): number | 'notCounted' => {
    if (notManual) return 'notCounted';
    return hand === 'Left' && (doc.manualScoreLeft || doc.manualScoreLeft === 0)
      ? parseInt(doc.manualScoreLeft)
      : hand === 'Right' && (doc.manualScoreRight || doc.manualScoreRight === 0)
        ? parseInt(doc.manualScoreRight)
        : 'notCounted';
  };

  // Make an array of tests that are related with the given hand parameter
  let fields = Object.keys(doc).filter(
    (n: string) =>
      n.substr(-hand.length, hand.length) === hand &&
      n.substr(0, 'manualscore'.length).toLocaleLowerCase() !== 'manualscore',
  );
  fields = fields.filter((f: string) => doc[f] !== ''); // Filter empty fields

  // Tests that are done with both hands
  // const entryItem = doc['entryItem'] === '' ? undefined : (doc['entryItem'] as number | undefined);
  const tearingPaper = doc['tearingPaper'] === '' ? undefined : (doc['tearingPaper'] as number | undefined);
  const weightFromLapToTable500G =
    doc['weightFromLapToTable500G'] === '' ? undefined : (doc['weightFromLapToTable500G'] as number | undefined);
  const bringBothArmsAboveHeadAbduction =
    doc['bringBothArmsAboveHeadAbduction'] === ''
      ? undefined
      : (doc['bringBothArmsAboveHeadAbduction'] as number | undefined);

  // If common items do not exists
  if (!exists(tearingPaper) || !exists(weightFromLapToTable500G) || !exists(bringBothArmsAboveHeadAbduction))
    return returnManualScore();

  // Applied to both scores
  const commonPoints = (tearingPaper || 0) + (weightFromLapToTable500G || 0) + (bringBothArmsAboveHeadAbduction || 0);

  // Calculate the values of given fields of document
  const returnableField = fields.map((f) => {
    const d = doc as any;
    if (d[f] === undefined) d[f] = '';
    if (f === 'manualScoreLeft' || f === 'manualScoreRight') return '';
    return d[f];
  });

  // - Return manualScore if the form is not filled.
  //
  // - Return the sum of values of the form procedure
  //   if form is fully filled.
  return fields.length < fieldCount ? returnManualScore() : sum(returnableField) + commonPoints;
};

/**
 * Find out which hand gets better RULM score
 * @param {object} doc - RULM document where the score is counted from
 * @returns { 'Left' | 'Right'} Hand
 */
export const rulmSuperiorHand = (doc?: { [key: string]: any }): 'Left' | 'Right' | undefined => {
  // Check if both hands are 'notCounted' and document is valid
  if (!doc || (rulmScore(doc, 'Right') === 'notCounted' && rulmScore(doc, 'Left') === 'notCounted')) {
    return undefined;
  }

  // Check if 'Right' is 'notCounted'
  if (rulmScore(doc, 'Right') === 'notCounted') {
    return 'Left';
  }

  // Check if 'Left' is 'notCounted'
  if (rulmScore(doc, 'Left') === 'notCounted') {
    return 'Right';
  }
  // When both hands have some number value score
  let temp: 'Left' | 'Right' = 'Left';
  if (rulmScore(doc, 'Right') >= rulmScore(doc, 'Left')) {
    temp = 'Right';
  } else if (rulmScore(doc, 'Left') > rulmScore(doc, 'Right')) {
    temp = 'Left';
  }
  return temp;
};

/**
 * Count score from a chopINTEND document
 * Requirements:
 * * Right or left field must be entered in section as number or CNT, otherwise return 'notCounted'
 * * return number when score can be counted
 * @param {object} doc - chopINTEND document where the score is counted from
 * @returns {number|'notCounted'} Score
 */
export const chopINTENDScore = (doc?: { [key: string]: any }): number | 'notCounted' => {
  const fieldCount = 16;

  if (!doc) return 'notCounted';

  const returnManual = (): number | 'notCounted' => doc.manualScore ?? 'notCounted'; // Hacky, refactor :)

  let rightFields = Object.keys(doc).filter((n: string) => n.substr(-5, 5) === 'Right');
  rightFields = rightFields.filter((f: string) => doc[f] !== ''); // Filter empty fields
  rightFields = rightFields.map((f: string) => f.split('Right')[0]); // Return field name without side

  let leftFields = Object.keys(doc).filter((n: string) => n.substr(-4, 4) === 'Left');
  leftFields = leftFields.filter((f: string) => doc[f] !== '');
  leftFields = leftFields.map((f: string) => f.split('Left')[0]);

  // Combine field names and remove duplicates
  const allFields = uniq([...rightFields, ...leftFields]);

  // If enough (right or left) fields are not filled
  if (allFields.length < fieldCount - 3) return returnManual();

  // Fields with no specific side (if CNT => 0, if '' => undefined)
  const headControl =
    doc['headControl'] === 'CNT'
      ? 0
      : doc['headControl'] === ''
        ? undefined
        : (doc['headControl'] as number | undefined);
  const neckFlexionScoreWithItem13 =
    doc['neckFlexionScoreWithItem13'] === 'CNT'
      ? 0
      : doc['neckFlexionScoreWithItem13'] === ''
        ? undefined
        : (doc['neckFlexionScoreWithItem13'] as number | undefined);
  const headNeckExtensionLandau =
    doc['headNeckExtensionLandau'] === 'CNT'
      ? 0
      : doc['headNeckExtensionLandau'] === ''
        ? undefined
        : (doc['headNeckExtensionLandau'] as number | undefined);
  // If these are not filled
  if (!exists(headControl) || !exists(neckFlexionScoreWithItem13) || !exists(headNeckExtensionLandau))
    return returnManual();

  // Get highest field values (compare right and left fields)
  const fields = allFields.map((f) => {
    const d = { ...(doc as any) };
    if (d[f + 'Left'] === undefined) d[f + 'Left'] = '';
    if (d[f + 'Right'] === undefined) d[f + 'Right'] = '';
    if (d[f + 'Right'] === 'CNT' && d[f + 'Left'] === 'CNT') return 0;
    else if (d[f + 'Right'] === 'CNT') return d[f + 'Left'];
    else if (d[f + 'Left'] === 'CNT') return d[f + 'Right'];
    return d[f + 'Right'] >= d[f + 'Left'] ? d[f + 'Right'] : d[f + 'Left'];
  });

  const commonPoints = (headControl || 0) + (neckFlexionScoreWithItem13 || 0) + (headNeckExtensionLandau || 0);

  return sum(fields) + commonPoints;
};

/**
 * Count score for HFMS-E document
 * @param {object} doc - HFMS-E document where the sums are counted from
 * @returns {number| 'notCounted'} Sums of the fields
 */
export const hfmsEScore = (doc?: IHFMSE): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  const returnManual = (): number | 'notCounted' => doc.manualScore ?? 'notCounted'; // Hacky, refactor :)

  const fieldCount = 33;

  const omitted: Array<keyof IHFMSE> = [
    ...controlProps,
    'date',
    'timeTakenToComplete',
    'highestCurrentLevelOfMobility',
    'highestCurrentLevelOfMobilityInfo',
    'manualScore',
    'oneHandToHeadInSittingSide',
  ];
  const notLBCFields: Array<keyof IHFMSE> = Object.keys(doc).filter((n: string) => n.substr(-3, 3) !== 'LBC') as Array<
    keyof IHFMSE
  >;
  const scoreFields = values(
    pick<Partial<IHFMSE>, keyof Partial<IHFMSE>>(notLBCFields, omit<IHFMSE, keyof IHFMSE>(omitted, doc)),
  ) as number[];
  // If not all fields have been filled
  if (scoreFields.length < fieldCount) return returnManual();
  return sum(scoreFields);
};

/**
 * NSAA score - doesnt check for manual score
 * @param {object} doc - NSAA document where the sums are counted from
 * @returns {number| 'notCounted'} Sums of the fields
 */
export const nsaaAutomaticScore = (doc?: INSAA): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  const fieldCount = 17;

  const omitted = [...controlProps, 'date', 'riseFromFloorTime', 'run10MTime', 'manualScore'] as Array<keyof INSAA>;
  const scoreFields = values(omit(omitted, doc)).filter((v) => v || v === 0);

  // Return either score calculated from the form or 'notCounted'
  return scoreFields.length < fieldCount ? 'notCounted' : sum(scoreFields);
};

/**
 * Count score for NSAA document
 * @param {object} doc - NSAA document where the sums are counted from
 * @returns {number| 'notCounted'} Sums of the fields
 */
export const nsaaScore = (doc?: INSAA): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  const returnManual = (): number | 'notCounted' => doc.manualScore ?? 'notCounted'; // Hacky, refactor :)

  const fieldCount = 17;

  const omitted = [...controlProps, 'date', 'riseFromFloorTime', 'run10MTime', 'manualScore'] as Array<keyof INSAA>;
  const scoreFields = values(omit(omitted, doc)).filter((v) => v || v === 0);

  // If all fields have not been filled, return manual score or 'notCounted'
  if (scoreFields.length < fieldCount) return returnManual();
  // otherwise return either manual score or score calculated from form
  return doc.manualScore || doc.manualScore === 0 ? doc.manualScore : sum(scoreFields);
};

export const showPatientAgeInMonthsOnForm = (documentDate?: PartialDate): boolean => {
  if (!documentDate || getPatientAgeInMonths(documentDate) < 0 || getPatientAgeInMonths(documentDate) > 48) {
    return false;
  }
  return true;
};

/**
 * function for checking which sections are not filled in rulm test
 * @param side left or right
 * @param doc formdata.document
 */
export const getMissingRulmSections = (side: 'Left' | 'Right', doc: IRULM): string => {
  const fields = [
    {
      field: 'handFromLapToTable',
      section: 'B',
    },
    {
      field: 'tracingAPath',
      section: 'C',
    },
    {
      field: 'pickUpTokens',
      section: 'D',
    },
    {
      field: 'placeTokenIntoCup',
      section: 'E',
    },
    {
      field: 'reachToSideAndTouchToken',
      section: 'F',
    },
    {
      field: 'pushButtonLight',
      section: 'G',
    },
    {
      field: 'tearingPaper',
      section: 'H',
    },
    {
      field: 'openZipLockContainer',
      section: 'I',
    },
    {
      field: 'cupToMouth200G',
      section: 'J',
    },
    {
      field: 'moveWeightOnTableHorizontal200G',
      section: 'K',
    },
    {
      field: 'moveWeightOnTableHorizontal500G',
      section: 'L',
    },
    {
      field: 'moveWeightOnTableDiagonal200G',
      section: 'M',
    },
    {
      field: 'weightFromLapToTable500G',
      section: 'N',
    },
    {
      field: 'bringBothArmsAboveHeadAbduction',
      section: 'O',
    },
    {
      field: 'aboveShoulderHeight500GAbduction',
      section: 'P',
    },
    {
      field: 'aboveShoulderHeight1KGAbduction',
      section: 'Q',
    },
    {
      field: 'handAboveShoulderHeightFlexion',
      section: 'R',
    },
    {
      field: 'aboveShoulderHeight500GFlexion',
      section: 'S',
    },
    {
      field: 'aboveShoulderHeight1KGFlexion',
      section: 'T',
    },
  ];
  const singleFields = ['H', 'N', 'O'];
  const missing: string[] = [];
  fields.forEach((obj: { field: string; section: string }) => {
    if (singleFields.includes(obj.section)) {
      if (
        (!doc[obj.field as keyof IRULM] || doc[obj.field as keyof IRULM] === '') &&
        doc[obj.field as keyof IRULM] !== 0
      ) {
        missing.push(obj.section);
      }
    } else {
      if (
        (!doc[(obj.field + side) as keyof IRULM] || doc[(obj.field + side) as keyof IRULM] === '') &&
        doc[(obj.field + side) as keyof IRULM] !== 0
      ) {
        missing.push(obj.section);
      }
    }
  });
  return missing.length === 0 ? '' : missing.join(', ');
};

export type TDocument = IHINEMM | IRULM | ICHOPINTEND | IHFMSE | I6MWT | INSAA | IMFM;

/** Function for checking if mfm test result is given manually */
export const isMfmManualComplete = (d: IMFM): boolean =>
  !!(d && d.manualD1Score && d.manualD2Score && d.manualD3Score && d.manualTotalScore);

/** Function for checking if mfm test is complete */
export const isMfmComplete = (d: IMFM): boolean => {
  if (d?.mfm32OrMfm20 === 'mfm32') {
    if (mfmSteps.filter((s) => s in d && (d[s] || d[s] === 0)).length === 32) {
      return true;
    } else {
      return false;
    }
  } else if (d?.mfm32OrMfm20 === 'mfm20') {
    if (mfmSteps.filter((s) => !disabledMfmSteps.includes(s) && s in d && (d[s] || d[s] === 0)).length === 20) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const mfmD1Steps: (keyof IMFM)[] = [
  'raisesThePelvis',
  'sitsUp',
  'standsUp',
  'sitsDown',
  'withoutUpperLimbSupportStandsUp',
  'maintainsAStandingPosition',
  'raisesTheFoot',
  'touchesTheFloor',
  'takesTenStepsForwardOnBothHeels',
  'takesTenStepsForwardOnALine',
  'runsTenMeters',
  'hopsTenTimes',
  'managesToSquatAndGetsUpTwiceInARow',
];
export const mfmD2Steps: (keyof IMFM)[] = [
  'holdsTheHead',
  'raisesTheHead',
  'flexesTheHip',
  'raisesTheHand',
  'turnsOverIntoProne',
  'isCapableOfMaintainingContact',
  'leansForward',
  'maintainsSeatedPosition',
  'fromHeadInCompleteFlexionRaisesTheHead',
  'placesBothHandsOnTopOfHead',
  'reachesThePencil',
  'placesTheTwoForearms',
];
const mfmD3Steps: (keyof IMFM)[] = [
  'dorsiflexesTheFoot',
  'picksUpAndHoldsTenCoins',
  'goesRoundTheEdgeOfTheCd',
  'picksUpPencil',
  'tearsTheSheetOfPaper',
  'picksUpTheBall',
  'raisesTheFinger',
];

/** Function for calculating mfm scores */
export const mfmScore = (
  d: IMFM,
  doNotTakeManualScoresIntoAction?: boolean, // If true, manualscores are not returned in any option
): { d1: string; d2: string; d3: string; total: string } => {
  if (
    d &&
    d.manualD1Score &&
    d.manualD2Score &&
    d.manualD3Score &&
    d.manualTotalScore &&
    !doNotTakeManualScoresIntoAction
  ) {
    return {
      d1: d.manualD1Score.toString() + '%',
      d2: d.manualD2Score.toString() + '%',
      d3: d.manualD3Score.toString() + '%',
      total: d.manualTotalScore.toString() + '%',
    };
  } else if (d && isMfmComplete(d)) {
    // If mfm20 test has been executed, the dividers are smaller as some questions are not answered
    const d1Divider = d.mfm32OrMfm20 === 'mfm32' ? 39 : 24;
    const d2Divider = d.mfm32OrMfm20 === 'mfm32' ? 36 : 24;
    const d3Divider = d.mfm32OrMfm20 === 'mfm32' ? 21 : 12;
    const totalDivider = d.mfm32OrMfm20 === 'mfm32' ? 96 : 60;
    return {
      d1: `${(
        (mfmD1Steps
          .filter((s) =>
            d.mfm32OrMfm20 === 'mfm20' && !disabledMfmSteps.includes(s)
              ? true
              : d.mfm32OrMfm20 === 'mfm32'
                ? true
                : false,
          )
          .map((s) => (s in d && (d[s] || d[s] === 0) ? (d[s] as number) : 0))
          .reduce((prev, curr) => prev + curr, 0) /
          d1Divider) *
        100
      ).toFixed(2)}%`,
      d2: `${(
        (mfmD2Steps
          .filter((s) =>
            d.mfm32OrMfm20 === 'mfm20' && !disabledMfmSteps.includes(s)
              ? true
              : d.mfm32OrMfm20 === 'mfm32'
                ? true
                : false,
          )
          .map((s) => (s in d && (d[s] || d[s] === 0) ? (d[s] as number) : 0))
          .reduce((prev, curr) => prev + curr, 0) /
          d2Divider) *
        100
      ).toFixed(2)}%`,
      d3: `${(
        (mfmD3Steps
          .filter((s) =>
            d.mfm32OrMfm20 === 'mfm20' && !disabledMfmSteps.includes(s)
              ? true
              : d.mfm32OrMfm20 === 'mfm32'
                ? true
                : false,
          )
          .map((s) => (s in d && (d[s] || d[s] === 0) ? (d[s] as number) : 0))
          .reduce((prev, curr) => prev + curr, 0) /
          d3Divider) *
        100
      ).toFixed(2)}%`,
      total: `${(
        (mfmSteps
          .filter((s) =>
            d.mfm32OrMfm20 === 'mfm20' && !disabledMfmSteps.includes(s)
              ? true
              : d.mfm32OrMfm20 === 'mfm32'
                ? true
                : false,
          )
          .map((s) => (s in d && (d[s] || d[s] === 0) ? (d[s] as number) : 0))
          .reduce((prev, curr) => prev + curr, 0) /
          totalDivider) *
        100
      ).toFixed(2)}%`,
    };
  }
  return { d1: `-`, d2: `-`, d3: `-`, total: `-` };
};
