import * as React from 'react';
import { Icons, TAvailableIcons, customViewBox, TCustomViewBoxKey } from './AvailableIcons';
import colors from '../../../config/theme/colors';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

const ColorMap: { [key: string]: string } = {
  appBlueMedium: colors.appBlue.medium,
  appBlueLight: colors.appBlue.light,
  warningDefault: colors.warning.default,
  errorDefault: colors.error.default,
  errorLight: colors.error.light,
  success: colors.success,
  grayMedium: colors.mediumGray,
  grayDark: colors.darkGray,
  color0: colors.warning.default,
  color1: '#960063', // Graph4
  color2: '#C20012', // Graph7
  color3: '#854EAF', // Graph11
};

/*
  This rounded style container and icon inside it are a bit wonky
  It should be the size of the supplied icon size, while the icon inside is a certain percentage smaller
  but depending where the icon is on the screen the alignment inside container might change
*/
const FilledStyle: (color: string | number, size: number, square?: boolean) => React.CSSProperties = (
  color,
  size,
  square,
) => ({
  width: size + 'rem',
  height: size + 'rem',
  backgroundColor: `${color in ColorMap ? ColorMap[color] : color}`,
  borderRadius: square ? '0.3rem' : '10rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const iconSizeFunc = (roundOrSquare: boolean, size: number, customIconSize?: number) => {
  if (!roundOrSquare) return size + 'rem';
  if (customIconSize) return customIconSize + 'rem';
  // Rounded multiplier needs to be tested if changed, so that it scales well
  return size * 0.84 + 'rem';
};

const Icon = ({
  icon,
  color = colors.primary,
  secondaryColor = 'white',
  size = 2.5,
  iconSize,
  round = false,
  square = false,
  button = false,
}: IIcon) => {
  const IconElement = button ? ButtonBase : React.Fragment;
  const buttonProps: ButtonBaseProps = button
    ? { centerRipple: true, focusRipple: true, style: { verticalAlign: 'top' } }
    : {};
  return (
    <IconElement {...buttonProps}>
      <div
        style={{
          ...(round || square ? FilledStyle(color, size, square) : {}),
        }}
      >
        {React.createElement(Icons[icon], {
          style: {
            color:
              (round || square) && secondaryColor && secondaryColor in ColorMap
                ? ColorMap[secondaryColor]
                : (round || square) && secondaryColor
                  ? secondaryColor
                  : color in ColorMap
                    ? ColorMap[color]
                    : color,
            fontSize: iconSizeFunc(round || square, size, iconSize),
            display: 'block',
          },
          viewBox: icon in customViewBox ? customViewBox[icon as TCustomViewBoxKey] : undefined,
        })}
      </div>
    </IconElement>
  );
};

type TMappedColors = keyof typeof ColorMap;

interface IIcon {
  icon: TAvailableIcons;
  color?: TMappedColors | string;
  secondaryColor?: TMappedColors | string;
  round?: boolean;
  square?: boolean;
  size?: number;
  /** Custom size for icon if it's rendered inside a circle or square */
  iconSize?: number;
  /** The icon is rendered inside a button container, also adding a ripple effect when pushed */
  button?: boolean;
}

export default Icon;
