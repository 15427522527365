import * as React from 'react';
import { path } from 'ramda';
import { formatPartialDate } from 'neuro-utils';
import Unit from 'Components/Unit';
import { fm } from 'Components/FormatMessage';

/**
 * Format Device/RMT value from preceding doctor's order as "[value][unit] ([date])" if found
 * @param {IDoctorsOrder | undefined} precedingDoctorsOrder - Preceding doctor's order document
 * @returns {JSX.Element} - Device RMT value formatted if found, '-' if not
 */
export const FormattedDoctorsOrderValue = ({
  name,
  precedingDoctorsOrder,
}: {
  name: 'device' | 'rmt';
  precedingDoctorsOrder?: IDoctorsOrder;
}): JSX.Element =>
  precedingDoctorsOrder ? (
    <React.Fragment>
      <div style={{ display: 'inline-flex' }}>
        <Unit unit={name === 'device' ? undefined : fm(`rtms.units.${name}`)}>
          <span style={{ fontWeight: 600 }}>{path(['rtms', name], precedingDoctorsOrder) ?? '-'}</span>
        </Unit>
      </div>
      <div style={{ display: 'inline-flex', fontWeight: 400 }}>
        {`\u00A0\u00A0(${formatPartialDate(precedingDoctorsOrder.date)})`}
      </div>
    </React.Fragment>
  ) : (
    <span style={{ fontWeight: 600 }}>{'-'}</span>
  );

/**
 *
 * @param {IRTMSTreatment} doc RMTS document
 * @param {'numberOfPulses'} name
 * @returns {number | null | undefined} amount of pulses in the RMTS treatment if not null
 */
export const calculateValue = (doc: IRTMSTreatment, name: 'numberOfPulses'): number | null | undefined => {
  const value = doc[name];
  if (!value || value === null) return;
  return Array.isArray(value) ? value.reduce((p, c) => (p ?? 0) + (!!c || c === 0 ? c : 0), 0) : 0;
};
