import * as React from 'react';

/**
 * Hook that will be used only at component mount render.
 * @param callback Callback function that will be used on mount render.
 * @param deps Dependency array for useEffect.
 * @param onUnmount Function that will be used on unmount.
 */
const useWhenMounted = (callback: () => void, deps: Array<any>, onUnmount?: () => void): void => {
  const isMountRender = React.useRef(true);

  React.useEffect(() => {
    let isMounted = true;
    if (isMountRender.current) {
      if (isMounted) callback();
      isMountRender.current = false;
    }

    return (): void => {
      onUnmount && onUnmount();
      isMounted = false;
    };
  }, [callback, deps, onUnmount]);
};

interface IFetchResult {
  loading: boolean;
  value?: any;
  error?: Error;
}

/**
 * Hook that will do fetching in a secure way for a mounted component.
 * @param fetch Function for fetching data with an api call.
 * @returns loading: Boolean to tell if fetching is still in progress, value: The result value of fetch, error: Catched error.
 */
const useFetch = (fetch: () => Promise<any>): IFetchResult => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<any>();
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);

    fetch()
      .then((r: Response) => {
        return r.json();
      })
      .then((v: any) => {
        isMounted && setValue(v);
      })
      .catch((e: Error) => {
        isMounted && setError(e);
      })
      .finally(() => {
        isMounted && setLoading(false);
      });

    return (): void => {
      isMounted = false;
    };
  }, [fetch]);

  return { loading: loading, value: value, error: error };
};

interface IDispatchResult {
  loading: boolean;
  promiseResult?: any;
  error?: Error;
}

/**
 * Hook that will do dispathing in a secure way for a mounted component.
 * @param dispatch Function for dispatching
 * @returns loading: Boolean to tell if fetching is still in progress, promiseResult: The result of the promise, error: Catched error.
 */
const useDispatch = (dispatch: () => Promise<any>): IDispatchResult => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [promiseResult, setPromiseResult] = React.useState<any>();
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);

    dispatch()
      .then((r: any) => {
        isMounted && setPromiseResult(r);
      })
      .catch((e: Error) => {
        isMounted && setError(e);
      })
      .finally(() => {
        isMounted && setLoading(false);
      });

    return (): void => {
      isMounted = false;
    };
  }, [dispatch]);

  return { loading: loading, promiseResult: promiseResult, error: error };
};

const myHooks = { useWhenMounted, useFetch, useDispatch };

export default myHooks;
