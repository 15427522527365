import { IDiseaseHistoryData } from 'Components/sq-graphics';
import { atcCodesByPlatform } from 'Routes/Medication/Document/config';
import { patientHasRespiratoryFailureDiagnosis, patientHasSleepApneaDiagnosis } from 'Routes/Treatment/utils';

const sleepApneaAndRespiratoryFailureData = (
  docs: Array<IControlProps>,
  fm: (id: string) => string,
): IDiseaseHistoryData => {
  const diagnosisDocs: Array<IDiagnosis> = [];
  docs
    .filter((doc) => doc._type === 'diagnosis')
    .forEach((doc) => {
      diagnosisDocs.push(doc);
    });

  const hasSleepApnea = patientHasSleepApneaDiagnosis(diagnosisDocs);
  const hasRespiratoryFailure = patientHasRespiratoryFailureDiagnosis(diagnosisDocs);
  const hasHadPAPTherapy = docs.some((doc) => doc._type === 'papTherapy');
  const hasHadOxygenTherapy = docs.some((doc) => doc._type === 'oxygenTherapy');
  const hasHadHFNCTherapy = docs.some((doc) => doc._type === 'hfncTherapy');
  const hasBeenInSleepStudyOrSleepPolygraphy = docs.some((doc) =>
    ['sleepStudy', 'sleepPolygraphy'].includes(doc._type),
  );
  const hasBeenInCarbonDioxideMeasurement = docs.some((doc) => doc._type === 'carbonDioxideMeasurement');
  const hasHadSelfAdjustingCPAPDevice = (docs.filter((doc) => doc._type === 'papTherapy') as IPAPTherapy[]).find(
    (doc) => {
      if ((doc.devices || []).find((d) => d.type === 'selfAdjustingCPAP')) return true;
      return false;
    },
  );

  const hasHadVenturiMask = (docs.filter((doc) => doc._type === 'oxygenTherapy') as IOxygenTherapy[]).find((d) => {
    if ((d.settings || []).find((s) => s.implementationOfTreatment === 'venturiMask')) return true;
    return false;
  });

  const clinicalMetersPap = fm('graph.clinicalMetersPap');
  const clinicalMeters = fm('graph.clinicalMetersOther');
  const patientSelfReport = fm('graph.patientSelfReport');

  const baseData: IDiseaseHistoryData = {
    graphData: {
      clinicalMetersPap: {
        deviceUsageHours: {
          data: [],
          title: fm('graph.deviceUsageHours'),
          scale: {
            type: 'linear',
          },
        },
        pap: {
          data: [],
          title: fm('graph.pap'),
          scale: {
            type: 'linear',
            linearScaleCalculator: (max: number) => (max <= 10 ? [0, 10] : [0, 15]),
          },
        },
        ahi: {
          data: [],
          title: 'AHI',
          scale: {
            type: 'linear',
          },
        },
        maskLeak95thPercentile: {
          data: [],
          title: fm('graph.maskLeak95thPercentile'),
          scale: {
            type: 'linear',
          },
        },
        aiCentral: {
          data: [],
          title: fm('graph.aiCentral'),
          scale: {
            type: 'linear',
          },
        },
        pressure95thPercentile: {
          data: [],
          title: fm('graph.pressure95thPercentile'),
          scale: {
            type: 'linear',
          },
        },
        tidalVolume95Percentile: {
          data: [],
          title: fm('treatment.papTherapy.tidalVolume95Percentile'),
          scale: {
            type: 'linear',
          },
        },
        respiratoryRate95Percentile: {
          data: [],
          title: fm('treatment.papTherapy.respiratoryRate95Percentile'),
          scale: {
            type: 'linear',
          },
        },
        spontT: {
          data: [],
          title: 'Spont T',
          scale: {
            type: 'linear',
          },
        },
        spontC: {
          data: [],
          title: 'Spont C',
          scale: {
            type: 'linear',
          },
        },
        targetVentilation: {
          data: [],
          title: fm('treatment.papTherapy.targetVentilation'),
          scale: {
            type: 'linear',
          },
        },
      },
      common: {
        tcCO2Mean: {
          data: [],
          title: fm('graph.tcCO2Mean'),
          scale: {
            type: 'linear',
          },
        },
        oxygenFlow: {
          data: [],
          title: `${fm('graph.oxygenFlowShort')} l/min`,
          scale: {
            type: 'linear',
          },
        },
        oxygenFlowPercent: {
          data: [],
          title: `${fm('graph.oxygenFlow')} %`,
          scale: {
            type: 'linear',
            linearScale: [0, 100],
          },
        },
        oxygenSaturation: {
          data: [],
          title: fm('graph.oxygenSaturation'),
          scale: {
            type: 'linear',
            linearScale: [40, 100],
          },
        },
        flowRate: {
          data: [],
          title: fm('graph.airFlow'),
          scale: {
            type: 'linear',
          },
        },
        supplementalOxygen: {
          data: [],
          title: fm('graph.oxygenPercent'),
          scale: {
            type: 'linear',
          },
        },
        bmi: {
          data: [],
          title: 'BMI',
          scale: {
            type: 'linear',
            linearScaleCalculator: (max: number): [number, number] => (max > 40 ? [0, 80] : [0, 40]),
          },
        },
      },
      selfReporting: {
        ess: {
          data: [],
          title: 'ESS',
          scale: {
            type: 'linear',
            linearScale: [0, 24],
          },
        },
        des: {
          data: [],
          title: 'DES',
          scale: {
            type: 'linear',
            linearScale: [0, 6],
          },
        },
        eq5d: {
          data: [],
          title: 'EQ-5D-5L',
          scale: {
            type: 'hybridCL',
            linearScale: [0, 1],
            customScale: ['< 0'],
          },
        },
      },
    },
    graphDataGroups: {
      clinicalMetersPap: {
        groupHeader: clinicalMetersPap,
        open: false,
      },
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: false,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    addonData: {
      treatments: {
        id: 'treatments',
        type: 'expansionPanel',
        name: fm('graph.treatments'),
        addons: [],
      },
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('graph.medications'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          atcCodesByPlatform['sleepApnea']
            // Filter out "extra" ATC codes
            .filter((code) => (!hasSleepApnea ? !['C01CA', 'N06BA14'].includes(code) : code !== 'C01CA'))
            .map((code) => {
              return { title: `${code} - ${fm(`graph.atcOpts.sleepApnea.${code}`)}` };
            }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('graph.endedMedications'),
        addons: [],
      },
      clpAndCnpTests: {
        id: 'clpAndCnpTests',
        type: 'expansionPanel',
        name: fm('graph.clpAndCnpTests'),
        addons: [],
      },
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('graph.diagnosis'),
        addons: [],
      },
      appointments: {
        id: 'appointments',
        type: 'single',
        name: fm('appointments.appointments'),
      },
    },
  };
  const returnableData = JSON.parse(JSON.stringify(baseData));
  Object.keys(baseData.graphData).forEach((data) => {
    const key = data as keyof IDiseaseHistoryData['graphData'];
    returnableData.graphData[key] = {};
    Object.keys(baseData.graphData[key]).forEach((k) => {
      switch (k) {
        case 'ahi': {
          if (key && k && (hasBeenInSleepStudyOrSleepPolygraphy || hasHadPAPTherapy)) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'pressure95thPercentile': {
          if (key && k && hasSleepApnea && hasHadSelfAdjustingCPAPDevice) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'pap':
        case 'maskLeak95thPercentile': {
          if (key && k && hasHadPAPTherapy) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'tcCO2Mean': {
          if (key && k && hasRespiratoryFailure && hasBeenInCarbonDioxideMeasurement) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'oxygenFlowPercent': {
          if (key && k && hasRespiratoryFailure && hasHadVenturiMask) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'oxygenFlow':
        case 'oxygenSaturation': {
          if (key && k && hasRespiratoryFailure && hasHadOxygenTherapy) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'flowRate':
        case 'supplementalOxygen': {
          if (key && k && hasRespiratoryFailure && hasHadHFNCTherapy) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'ess': {
          if (key && k && hasSleepApnea) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        case 'des': {
          if (key && k && hasRespiratoryFailure) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
          break;
        }
        default: {
          if (key && k) {
            returnableData['graphData'][key][k] = baseData['graphData'][key][k];
          }
        }
      }
    });
  });
  return returnableData;
};

export default sleepApneaAndRespiratoryFailureData;
