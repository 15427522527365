import { path, find, values, omit, sum } from 'ramda';
import { controlProps } from '../../../utility/documentHandling';

export type TDocument = IMADRS | IGAF;

export const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : undefined;

export const getHeaderId = (documents: Array<TDocument>, editing: any, viewing: any): string =>
  docType(documents, editing ?? viewing) === 'madrs'
    ? 'interviewSurveys.madrs.title'
    : docType(documents, editing ?? viewing) === 'gaf'
      ? 'interviewSurveys.gaf.title'
      : 'interviewSurveys.title';

/**
 * function for calculating total score of madrs
 * @param document formdata.document
 *
 */
export const madrsTotalScore = (doc?: IMADRS): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  const fieldCount = 10;

  const omitted: Array<keyof IMADRS> = [...controlProps, 'date', 'details'];
  const scoreFields = values(omit(omitted, doc)).filter((v) => v || v === 0);

  // If all fields have not been filled, return manual score or 'notCounted'
  if (scoreFields.length < fieldCount) return 'notCounted';
  // otherwise return either manual score or score calculated from form
  return sum(scoreFields);
};
/**
 * Function for getting the key of MÅDRS total score description used in fm() func
 * @param {number | 'notCounted'} totalScore total score of the MÅDRS doc
 * @return {string | undefined} last key string to use in fm('interviewSurveys.madrs.scoreDescriptions.@return'). Undefined if param less than 0.
 */
export const getMadrsDescription = (totalScore: number | 'notCounted'): string | undefined => {
  if (totalScore === 'notCounted' || totalScore < 0) return undefined;
  if (totalScore <= 7) {
    return '0-7';
  } else if (totalScore <= 14) {
    return '8-14';
  } else if (totalScore <= 24) {
    return '15-24';
  } else if (totalScore <= 30) {
    return '25-30';
  } else if (totalScore <= 43) {
    return '31-43';
  } else {
    return '44+';
  }
};

export const gafClasses = [
  '100-91',
  '90-81',
  '80-71',
  '70-61',
  '60-51',
  '50-41',
  '40-31',
  '30-21',
  '20-11',
  '10-01',
  '0',
];

/**
 * Returns gaf class for score
 * @param {number} score - Gaf score
 * @returns {string} Gaf class
 */
export const gafScoreToClass = (score: number) => {
  if (score === 0) return gafClasses[10];
  if (score > 0 && score <= 10) return gafClasses[9];
  if (score > 10 && score <= 20) return gafClasses[8];
  if (score > 20 && score <= 30) return gafClasses[7];
  if (score > 30 && score <= 40) return gafClasses[6];
  if (score > 40 && score <= 50) return gafClasses[5];
  if (score > 50 && score <= 60) return gafClasses[4];
  if (score > 60 && score <= 70) return gafClasses[3];
  if (score > 70 && score <= 80) return gafClasses[2];
  if (score > 80 && score <= 90) return gafClasses[1];
  if (score > 90 && score <= 100) return gafClasses[0];
  return null;
};
