import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { path, find } from 'ramda';
import * as React from 'react';
import colors from '../../../config/theme/colors';

export const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : undefined;

export type TDocument =
  | IDEXA
  | ICMAP
  | IMuscleImaging
  | IEchocardiography
  | IScoliosisRontgen
  | IPelvicRontgen
  | IMRI
  | ITT
  | IScintigraphy;

export const convertTrendToIcon = (trend: string | undefined, invertColors?: boolean): JSX.Element | null => {
  const iconStyle = {
    fontSize: '1.9rem',
    display: 'inline-block',
    backgroundColor:
      trend === 'decreased'
        ? colors[invertColors ? 'trendingNegativeBackground' : 'trendingPositiveBackground']
        : trend === 'same'
          ? colors.trendingNeutralBackground
          : trend === 'increased'
            ? colors[invertColors ? 'trendingPositiveBackground' : 'trendingNegativeBackground']
            : undefined,
    padding: '0 0.4rem 0 0.4rem',
    borderRadius: '0.5rem',
    verticalAlign: 'middle',
    marginTop: '-0.3rem',
  };

  return trend === 'decreased' ? (
    <TrendingDown style={{ color: colors[invertColors ? 'trendingNegative' : 'trendingPositive'], ...iconStyle }} />
  ) : trend === 'same' ? (
    <TrendingFlat style={{ color: colors.trendingNeutral, ...iconStyle }} />
  ) : trend === 'increased' ? (
    <TrendingUp style={{ color: colors[invertColors ? 'trendingPositive' : 'trendingNegative'], ...iconStyle }} />
  ) : null;
};
