import { equals, filter, includes, isEmpty, keys, omit, sort, uniq } from 'ramda';

import { isPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { leadConfiguration } from '../Document/config';
import { ILeadClickerType } from '../Document/Form/LeadClicker';

/**
 * Find active DBS treatment
 * @param {IDBS[]} documents - All DBS documents
 * @returns {IDBS[]} Active DBS documents
 */
export const findActive = (documents: IDBS[]): IDBS[] => filter((d: IDBS) => d.hasEnded?.[0] !== true, documents);

/**
 * Find ended DBS
 * @param {IDBS[]} documents - All DBS documents
 * @returns {IDBS[]} Ended DBS documents
 */
export const findEnded = (documents: IDBS[]): IDBS[] => documents.filter((item: IDBS) => item.hasEnded?.[0] === true);

/**
 * Get latest event from Discontinuations
 * @param {IDBSDiscontinuationEvent[]} events - Discontinuations array
 * @returns {IDBSDiscontinuationEvent|undefined} Latest DiscontinuationEvent or undefined
 */
export const getLatestDiscontinuation = (events?: IDBSDiscontinuationEvent[]): IDBSDiscontinuationEvent | undefined => {
  if (!events || events.length === 0) {
    return undefined;
  }
  const sorted = events
    .slice()
    .sort((n1: IDBSDiscontinuationEvent, n2: IDBSDiscontinuationEvent) => sortPartialDate(n1.date, n2.date))
    .reverse();
  return sorted[0];
};

/**
 * Get latest active discontinuation
 * @param {IDBSDiscontinuationEvent[]} events - Discontinuations array
 * @returns {IDBSDiscontinuationEvent | undefined} - Latest active discontinuation
 */
const getLatestActiveDiscontinuation = (events?: IDBSDiscontinuationEvent[]): IDBSDiscontinuationEvent | undefined => {
  if (!events || events.length === 0) {
    return undefined;
  }
  const latestActive = events
    .slice()
    .sort((n1: IDBSDiscontinuationEvent, n2: IDBSDiscontinuationEvent) => sortPartialDate(n2.date, n1.date))
    .find((d) => !d.endDate);
  return latestActive;
};

/**
 * Handle discontinuations and continuations in DBS form and set treatment status accordingly
 * @param {any} onChange - onChange function for updating document values
 * @param {IDBSDiscontinuationEvent[] | undefined} discontinuations - Array of discontinuation events in the document
 * @param {IDBSGenerator[] | undefined} generators - Array of generators in the document
 * @param {string} status - Current status of the treatment
 * @param {boolean} hasEnded - True if treatment has been marked as ended, false if not
 */
export const discontinuationHandler = (
  onChange: IFormData['onChange'],
  discontinuations: Array<IDBSDiscontinuationEvent> | undefined,
  generators: Array<IDBSGenerator> | undefined,
  status: string,
  hasEnded: boolean,
): void => {
  const newDiscontinuations = JSON.parse(JSON.stringify(discontinuations ?? []));
  const removalDiscontinuations = newDiscontinuations.filter(
    (d: IDBSDiscontinuationEvent) => d.discontinuationType !== 'generatorSwitchedOff',
  );

  const newGenerators = JSON.parse(JSON.stringify(generators ?? []));
  const latestDiscontinuation = getLatestActiveDiscontinuation(newDiscontinuations);

  const updateDiscontinuationEndDates = (): void => {
    newGenerators.forEach((g: IDBSGenerator): void => {
      newDiscontinuations.forEach((d: IDBSDiscontinuationEvent, i: number): void => {
        if (d.discontinuationType !== 'generatorSwitchedOff' && sortPartialDate(g.generatorDate, d.date) >= 0) {
          newDiscontinuations[i] = { ...newDiscontinuations[i], endDate: g.generatorDate };
          return;
        }
      });
    });
  };

  const removeDiscontinuationEndDates = (): void => {
    removalDiscontinuations
      .filter(
        (d: IDBSDiscontinuationEvent) =>
          !newGenerators
            .slice(0, newGenerators.length - 1)
            .some((g: IDBSGenerator) => equals(d.endDate, g.generatorDate)),
      )
      .forEach((r: IDBSDiscontinuationEvent) => {
        const targetIndex = newDiscontinuations.indexOf(r);
        if (targetIndex >= 0) {
          newDiscontinuations[targetIndex] = { ...newDiscontinuations[targetIndex], endDate: undefined };
        }
      });
  };

  if (hasEnded) {
    status !== 'ended' && onChange?.({ status: 'ended' });
  }

  if (!Array.isArray(newDiscontinuations) || newDiscontinuations.length < 1) {
    !hasEnded && status !== 'inProgress' && onChange?.({ status: 'inProgress' });
  }

  if (latestDiscontinuation) {
    if (latestDiscontinuation.discontinuationType === 'generatorSwitchedOff') {
      !hasEnded &&
        !isPartialDate(latestDiscontinuation.endDate) &&
        status !== 'suspended' &&
        onChange?.({ status: 'suspended' });
    } else {
      if (newGenerators.length < removalDiscontinuations.length + 1) {
        !hasEnded && status !== 'suspended' && onChange?.({ status: 'suspended' });
      }
      if (newGenerators.length >= removalDiscontinuations.length + 1) {
        updateDiscontinuationEndDates();
        removeDiscontinuationEndDates();

        !equals(newDiscontinuations, discontinuations) && onChange?.({ discontinuationEvents: newDiscontinuations });
      }
      if (removalDiscontinuations.find((d: IDBSDiscontinuationEvent) => !d.endDate)) {
        !hasEnded && status !== 'suspended' && onChange?.({ status: 'suspended' });
      }
    }
  } else {
    if (newGenerators.length >= removalDiscontinuations.length + 1) {
      !hasEnded && status !== 'inProgress' && onChange?.({ status: 'inProgress' });

      updateDiscontinuationEndDates();
      removeDiscontinuationEndDates();

      !equals(newDiscontinuations, discontinuations) && onChange?.({ discontinuationEvents: newDiscontinuations });
    } else {
      !hasEnded && status !== 'suspended' && onChange?.({ status: 'suspended' });

      removeDiscontinuationEndDates();

      !equals(newDiscontinuations, discontinuations) && onChange?.({ discontinuationEvents: newDiscontinuations });
    }
  }
};

/**
 * Manage generator settings based on actions/changes done to generator(s)
 * @param {IFormData} formData - formData that has DBS documents and onChange function for updating the values
 * @param {'generator_deleted' | 'generator_changed' | 'generator_date_changed'} action - type of action done to generator(s)
 * @param {IDBSGenerator} generator - the generator related to the change
 */
export const generatorSettingHandler = (
  formData: IFormData,
  action: 'generator_deleted' | 'generator_changed' | 'generator_date_changed',
  generator?: IDBSGenerator,
): void => {
  const arraysAreValid = Array.isArray(formData.document.settings) && Array.isArray(formData.document.generators);
  switch (action) {
    case 'generator_deleted':
    case 'generator_changed': {
      if (arraysAreValid) {
        const newSettings = formData.document.settings.filter(
          (setting: IDBSSetting) => setting.generator !== generator?.id,
        );
        formData.onChange?.({ settings: newSettings });
      }
      break;
    }
    default: {
      return;
    }
  }
};

/**
 * Merge lead settings from two programs
 * @param {IProgram} document - The program which the settings are merged to
 * @param {IProgram} referenceDocument - The program which the settings are merged from
 * @returns {IProgram} Program object with the merged settings
 */
export const mergeLeadSettings = (
  document: ILeadClickerType,
  referenceDocument: ILeadClickerType,
): ILeadClickerType => {
  const merged = {
    ...document,
    rightSettings: Object.keys(referenceDocument.rightSettings ?? {}).reduce(
      (current, key) => {
        if (!document.rightSettings || document.rightSettings[key]) {
          current[key] = referenceDocument.rightSettings?.[key];
        }
        return current;
      },
      Object.assign({}, document.rightSettings, referenceDocument.rightSettings) ?? {},
    ),
    leftSettings: Object.keys(referenceDocument.leftSettings ?? {}).reduce(
      (current, key) => {
        if (!document.leftSettings || document.leftSettings[key]) {
          current[key] = referenceDocument.leftSettings?.[key];
        }
        return current;
      },
      Object.assign({}, document.leftSettings, referenceDocument.leftSettings) ?? {},
    ),
  };
  return merged;
};

/**
 * Filter undefined fields in an object
 * @param {object} data - object
 * @returns {object} Data without undefined fields
 */
export const filterUndefinedFields = (data: object): object => {
  const keysOfUndefinedFields = keys(data).filter((key) => data[key] === undefined);
  const filteredData = omit(keysOfUndefinedFields, data);
  return filteredData;
};

/**
 * Filter 'plus' and 'minus' fields in an object
 * @param {object} data - object
 * @returns {object} Data without undefined fields
 */
export const filterPlusMinusFields = (data: object): object => {
  const keysOfUndefinedFields = keys(data).filter((key) => ['plus', 'minus'].includes(data[key]));
  const filteredData = omit(keysOfUndefinedFields, data);
  return filteredData;
};

/**
 * Sort generators by date and save the changes
 * @param {IOwnProps['formData']} formData - current formData
 */
export const sortByDateAndSave = (formData: IOwnProps['formData']): void => {
  let events = [...(formData.document.generators || [])] as IDBSGenerator[];
  events = events.sort((n1, n2) => sortPartialDate(n1.generatorDate, n2.generatorDate)).reverse();
  formData.onChange && formData.onChange({ generators: events });
};

/**
 * Set DBS treatment status to 'inProgress' if it's not already 'inProgress' or 'ended'
 * @param {IOwnProps['formData']} formData - current formData
 */
export const setStatus = (formData: IOwnProps['formData']): void => {
  if (formData.document.status != 'inProgress' && formData.document.status != 'ended') {
    formData.onChange && formData.onChange({ status: 'inProgress' });
  }
};

/**
 * Get generators that are defined as rechargeable
 * @param {string} platform Active platform
 * @returns {string[]} Names of rechargeable generators
 */
const getRechargeableGenerators = (platform: string): string[] => {
  const keys = Object.keys(leadConfiguration[platform]);
  const rechargeable = [];
  for (let i = 0; i < keys.length; i++) {
    if (leadConfiguration[platform][keys[i]].rechargeable === true) {
      rechargeable.push(keys[i]);
    }
  }
  return rechargeable;
};

/**
 * Get generators that are defined as primaryCell (!rechargeable)
 * @param {string} platform Active plaform
 * @returns {string[]} Names of primaryCell generators
 */
const getPrimaryCellGenerators = (platform: string): string[] => {
  const keys = Object.keys(leadConfiguration[platform]);
  const primaryCell = [];
  for (let i = 0; i < keys.length; i++) {
    if (leadConfiguration[platform][keys[i]].rechargeable === false) {
      primaryCell.push(keys[i]);
    }
  }
  return primaryCell;
};

/**
 * Check if given generator is rechargeable
 * @param {string} generatorName - generator name
 * @param {string} platform - active plaform
 * @returns {boolean} - true if generator is rechargeable, false if not
 */
export const isRechargeable = (generatorName?: string, platform?: string | null): boolean => {
  let is = false;
  if (!generatorName || !platform) return is;
  const keys = Object.keys(leadConfiguration[platform]);
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === generatorName) {
      is = leadConfiguration[platform][keys[i]].rechargeable;
    }
  }
  return is;
};

/**
 * Get rechargeable generators that are compatible with given leads
 * @param {string} rightLead - right lead name
 * @param {string} leftLead - left lead name
 * @param {string} platform - active platform
 * @returns {string[] | undefined} - rechargeable generators compatible with given leads
 */
const backwardsCompatibilityRechargeable = (
  rightLead: string,
  leftLead: string,
  platform: string,
): string[] | undefined => {
  return (
    getRechargeableGenerators(platform).filter(
      (g: string) =>
        includes(rightLead, leadConfiguration[platform][g].leads) &&
        includes(leftLead, leadConfiguration[platform][g].leads),
    ) || undefined
  );
};

/**
 * Get primaryCell generators that are compatible with given leads
 * @param {string} rightLead - right lead name
 * @param {string} leftLead - left lead name
 * @param {string} platform - active platform
 * @returns {string[] | undefined} - primaryCell generators compatible with given leads
 */
const backwardsCompatibilityPrimaryCell = (
  rightLead: string,
  leftLead: string,
  platform: string,
): string[] | undefined => {
  return (
    getPrimaryCellGenerators(platform).filter(
      (g: string) =>
        includes(rightLead, leadConfiguration[platform][g].leads) &&
        includes(leftLead, leadConfiguration[platform][g].leads),
    ) || undefined
  );
};

/**
 * Get all generators that are compatible with given leads
 * @param {string} rightLead - right lead name
 * @param {string} leftLead - left lead name
 * @param {string} platform - active platform
 * @returns {BackwardsCompatibility[]} - all generators compatible with given leads
 */
export const backwardsCompatibility = (
  rightLead: string,
  leftLead: string,
  platform: string,
): BackwardsCompatibility[] => {
  return [
    { title: 'dbs.rechargeables', values: backwardsCompatibilityRechargeable(rightLead, leftLead, platform) },
    { title: 'dbs.primaryCells', values: backwardsCompatibilityPrimaryCell(rightLead, leftLead, platform) },
  ].filter((generators) => !isEmpty(generators.values));
};

/**
 * Check if there are no generators available for current selections
 * @param {IDBS} document - DBS document
 * @param {string} platform - Active platform
 * @returns {boolean} - true if there are no generators available, false if some are available
 */
export const generatorsUnavailable = (document: Partial<IDBS>, platform: string): boolean => {
  return document.leadRight && document.leadLeft && document.targetNucleus
    ? !backwardsCompatibility(document.leadRight, document.leadLeft, platform ?? 'parkinson').some(
        (generators: BackwardsCompatibility) => !isEmpty(generators.values),
      )
    : true;
};

/**
 * Check if generators have been added to current document
 * @param {IDBS} document - DBS document
 * @returns {boolean} - true if there are recorded generators, false if not
 */
export const generatorsExist = (document: Partial<IDBS>): boolean =>
  !document.generators || isEmpty(document.generators) ? false : true;

/**
 * Find the generator that follows given generator chronologically
 * @param {IDBSGenerator} generator - The generator from which to start looking for the next generator
 * @param {IDBS} document - The DBS document where the next generator is looked for
 * @returns {IDBSGenerator | undefined} - The next generator if found, undefined if not
 */
export const findNextGenerator = (generator: IDBSGenerator, document: IDBS): IDBSGenerator | undefined => {
  const generatorsSorted = sort(
    (a, b) => sortPartialDate(b.generatorDate, a.generatorDate),
    document?.generators ?? [],
  );
  const currentIndex = generatorsSorted.findIndex((g) => equals(generator, g));

  return currentIndex > 0 ? generatorsSorted[currentIndex - 1] : undefined;
};

/**
 * Returns endDate of the treatment or generatorNextChangeDate depending on whether the endDate or nextChangeDate is sooner
 * @param document - DBS document
 * @param index - index of the current generator being edited
 */
export const generatorDateHook = (document: IDBS, index: number): PartialDate | undefined => {
  const end = document.endDate;
  const change = document?.generators?.[index]?.generatorNextChangeDate;
  if (document?.hasEnded?.[0] === true && end && change && partialDateToValue(end) > partialDateToValue(change)) {
    return change;
  } else if (document.hasEnded?.[0] === true && end) {
    return end;
  } else if (change) {
    return change;
  } else return undefined;
};

/**
 * A function for deciding whether to render the latest generator or not.
 * Viimeisin generaattori siis näytetään joissain tapauksissa tämänhetkisenä generaattorina,
 * mutta on tapauksia jossa viimeisin generaattori ei ole tällä hetkellä käytössä
 * @param formData formData
 * @param editingGenerator boolean that tells us whether a generator is being edited or not
 */
export const renderLatestGeneratorOrNot = (
  formData: { onChange?: any; document: IDBS },
  editingGenerator: boolean | number,
): boolean => {
  if (
    formData.document?.generators &&
    formData.document.generators.length > 0 &&
    editingGenerator !== true &&
    (formData.document.status === 'inProgress' ||
      (formData.document.status === 'ended' && formData.document.removed != 'yes') ||
      (formData.document.status === 'suspended' &&
        getLatestDiscontinuation(
          formData?.document?.discontinuationEvents ? formData.document.discontinuationEvents : [],
        ) &&
        getLatestDiscontinuation(
          formData?.document?.discontinuationEvents ? formData.document.discontinuationEvents : [],
        )?.discontinuationType === 'generatorSwitchedOff'))
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * A function for deciding whether to render prvious generators or not
 * @param formData formData
 */
export const renderPreviousGeneratorsOrNot = (formData: { onChange?: any; document: IDBS }): boolean => {
  if (
    formData.document?.generators &&
    (formData.document.generators.length > 1 ||
      (formData.document.status === 'suspended' &&
        formData.document.generators.length > 0 &&
        getLatestDiscontinuation(
          formData?.document?.discontinuationEvents ? formData.document.discontinuationEvents : [],
        ) &&
        getLatestDiscontinuation(
          formData?.document?.discontinuationEvents ? formData.document.discontinuationEvents : [],
        )?.discontinuationType != 'generatorSwitchedOff') ||
      (formData.document.status === 'ended' &&
        formData.document.removed === 'yes' &&
        formData.document.generators.length > 0))
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Get all unique lead options
 * @param {string} platform Active platform
 * @returns {string[]} String array that contains all leads (no duplicates)
 */
export const getAllLeads = (platform: string): string[] => {
  const leadOptions: string[] = [];
  Object.keys(leadConfiguration[platform]).forEach((key: string) =>
    leadConfiguration[platform][key]['leads'].forEach((lead: string) => leadOptions.push(lead)),
  );
  return uniq(leadOptions.slice().sort());
};

/**
 * Get all lead options that are compatible with selected lead (they have at least one generator available)
 * @param {string} lead Selected lead (right or left)
 * @param {string} platform Active platform
 * @returns {string[]} String array that contains tall leads that are compatible with selected lead (no duplicates)
 */
export const getCompatibleLeads = (lead: string, platform: string): string[] => {
  const leadOptions: string[] = [];
  Object.keys(leadConfiguration[platform]).forEach((key: string) =>
    leadConfiguration[platform][key]['leads'].forEach((lead: string) => leadOptions.push(lead)),
  );
  return uniq(leadOptions)
    .filter((leadOption) => {
      return (
        !isEmpty(backwardsCompatibilityRechargeable(leadOption, lead, platform)) ||
        !isEmpty(backwardsCompatibilityPrimaryCell(leadOption, lead, platform))
      );
    })
    .slice()
    .sort();
};

/**
 * Check if the LFP registration program specific for Medtronic Percept PC exists in the setting
 * @param {string} setting Setting to be checked
 * @returns {boolean} True if exists, false if not
 */
export const hasLfpRegistration = (setting?: IDBSSetting): boolean =>
  setting && Array.isArray(setting.programs)
    ? setting.programs?.filter((program: IProgram) => program?.lfpRegistration === true).length > 0
    : false;

interface BackwardsCompatibility {
  title: string;
  values: string[] | undefined;
}

interface IOwnProps {
  formData: IFormData<IDBS>;
}
