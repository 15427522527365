import { fetchWithOptions } from 'Utility/fetch';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { makeLog } from 'Utility/logger';
import { formatPartialDate } from 'neuro-utils';
import { TLink, TPatient, TProfileData, TLocation } from './interfaces';

/**
 * Fetch neuro->AVX patient link information
 */
const fetchAvxPatientLink = (): Promise<Array<TLink> | null> => {
  return fetchWithOptions(`/api/resmed/v1/link`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX patient link fetch', message: 'Response code not 200' }, err);
      return null;
    });
};

/**
 * Fetch patient info by ECN from AVX
 */
const fetchAvxPatientInfo = (ecn: string): Promise<TPatient | null> => {
  return fetchWithOptions(`/api/resmed/v1/patient/${ecn}`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX patient info fetch', message: 'Response code not 200' }, err);
      return null;
    });
};

/**
 * Search AVX for patient information based on the neuro patient
 */
const search = (serialNo: string): Promise<TPatient> => {
  return fetchWithOptions(
    `/api/resmed/v1/search`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: serialNo },
  ).then(
    (res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    },
    (err: Error) => {
      throw err;
    },
  );
};

/**
 * Create a link between the given neuro patient and AVX ECN
 */
const linkAvxPatient = (ecn: string): Promise<string | null> => {
  const body = { ecn: ecn };

  return fetchWithOptions(
    `/api/resmed/v1/link`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(body) },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX patient link create', message: 'Response code not 200' }, err);
      return null;
    });
};

/**
 * Creates the patient to AVX external system and establishes a link with the created ECN and neuro patientId
 */
const createAvxPatient = (data: TProfileData): Promise<Array<TLink> | null> => {
  const dataFormatted = { ...data, setupDate: formatPartialDate(data.setupDate) };

  return fetchWithOptions(
    `/api/resmed/v1/patient`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(dataFormatted) },
  )
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX patient create', message: 'Response code not 200' }, err);
      return null;
    });
};

/**
 * Update an existing patients data in AVX external system
 */
const updateAvxPatient = (data: { ecn: string } & Partial<TProfileData>): Promise<boolean> => {
  const dataFormatted = { ...data, setupDate: formatPartialDate(data.setupDate) };

  return fetchWithOptions(
    `/api/resmed/v1/patient`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PATCH', body: JSON.stringify(dataFormatted) },
  )
    .then((res) => {
      if (res.status === 200) {
        return true;
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX patient update', message: 'Response code not 200' }, err);
      return false;
    });
};

/**
 * Fetch organizations locations from AVX, locations are needed for AVX patient create route
 */
const fetchLocations = (): Promise<TLocation[] | null> => {
  return fetchWithOptions(`/api/resmed/v1/locations`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX location fetch', message: 'Response code not 200' }, err);
      return null;
    });
};

export {
  fetchAvxPatientLink,
  fetchAvxPatientInfo,
  search,
  linkAvxPatient,
  createAvxPatient,
  updateAvxPatient,
  fetchLocations,
};
