import { IDiseaseHistoryData, IGraphData } from 'Components/sq-graphics';
import { atcCodesByPlatform } from 'Routes/Medication/Document/config';

/*
const topdataTitle = (ageMonths: number | undefined): any => {
  if ((ageMonths || 60) < 60) {
    return 'graph.ageMonths';
  } else {
    return 'graph.ageYears';
  }
};*/

const smaData = (
  ageMonths: number | undefined,
  fm: (id: string) => string,
  isSma1Diag: boolean,
  hasChopIntend: boolean,
  hasHineMM: boolean,
): IDiseaseHistoryData => {
  const commonGraphDatas: {
    [key: string]: IGraphData;
  } = {
    hfmse: {
      data: [],
      title: 'HFMS-E',
      scale: {
        type: 'linear',
        linearScale: [0, 66],
      },
    },
    rulm: {
      data: [],
      title: 'RULM',
      scale: {
        type: 'linear',
        linearScale: [0, 45],
      },
    },
    mfm20: {
      data: [],
      title: 'MFM 20',
      scale: {
        type: 'linear',
        linearScale: [0, 100],
      },
    },
    mfm32: {
      data: [],
      title: 'MFM 32',
      scale: {
        type: 'linear',
        linearScale: [0, 100],
      },
    },
    _6mwt: {
      data: [],
      title: '6MWT',
      scale: {
        type: 'linear',
      },
    },
    weights: {
      data: [],
      title: fm('background.weightKG'),
      scale: {
        type: 'linear',
      },
    },
    heights: {
      data: [],
      title: fm('background.heightCM'),
      scale: {
        type: 'linear',
      },
    },
  };
  const chopIntend: {
    [key: string]: IGraphData;
  } = {
    chopintend: {
      data: [],
      title: 'CHOP INTEND',
      scale: {
        type: 'linear',
        linearScale: [0, 64],
      },
    },
  };
  const hineMM: {
    [key: string]: IGraphData;
  } = {
    hinemm: {
      data: [],
      title: 'HINE MM',
      scale: {
        type: 'linear',
        linearScale: [0, 26],
      },
    },
  };
  const graphData: {
    [key: string]: IGraphData;
  } = isSma1Diag
    ? { ...chopIntend, ...hineMM, ...commonGraphDatas }
    : hasChopIntend && hasHineMM
      ? { ...chopIntend, ...hineMM, ...commonGraphDatas }
      : hasChopIntend && !hasHineMM
        ? { ...chopIntend, ...commonGraphDatas }
        : !hasChopIntend && hasHineMM
          ? { ...hineMM, ...commonGraphDatas }
          : commonGraphDatas;

  const clinicalMeters = fm('graph.clinicalMeters');
  const patientSelfReport = fm('graph.patientSelfReport');

  return {
    graphData: {
      common: graphData,
      selfReporting: {
        pedsqlNeuromuscular: {
          data: [],
          title: fm('myService.sma.opts.pedsqlNeuromuscular'),
          scale: {
            id: 'pedsqlNeuromuscular',
            type: 'linear',
            linearScale: [0, 100],
          },
        },
        pedsqlFatigue: {
          data: [],
          title: fm('myService.sma.opts.pedsqlFatigue'),
          scale: {
            id: 'pedsqlFatigue',
            type: 'linear',
            linearScale: [0, 72],
          },
        },
      },
    },
    graphDataGroups: {
      common: {
        groupHeader: clinicalMeters,
        open: true,
      },
      selfReporting: {
        open: false,
        groupHeader: patientSelfReport,
        theme: 'myms',
      },
    },
    addonData: {
      motorFunctions: {
        id: 'motorFunctions',
        type: 'expansionPanel',
        name: fm('graph.motorFunctions'),
        addons: [],
      },
      medications: {
        id: 'medications',
        type: 'expansionPanel',
        name: fm('graph.medications'),
        expansionPanelTitleDescription: [
          {
            title: fm('graph.atc-codes'),
            style: { fontWeight: 600 },
          },
        ].concat(
          atcCodesByPlatform['sma'].map((code) => {
            return { title: `${code} - ${fm(`graph.atcOpts.sma.${code}`)}` };
          }) as [],
        ),
        addons: [],
        expansionPanelShowAlways: true,
      },
      endedMedications: {
        id: 'endedMedications',
        type: 'expansionPanel',
        name: fm('graph.endedMedications'),
        addons: [],
      },
      therapies: {
        id: 'therapies',
        type: 'expansionPanel',
        name: fm('graph.therapies'),
        addons: [],
      },
      aids: {
        id: 'aids',
        type: 'expansionPanel',
        name: fm('graph.aids'),
        addons: [],
      },
      studies: {
        id: 'studies',
        type: 'expansionPanel',
        name: fm('graph.studies'),
        addons: [],
      },
      comorbiditiesAndHospitalizations: {
        id: 'comorbiditiesAndHospitalizations',
        type: 'expansionPanel',
        name: fm('graph.comorbiditiesAndHospitalizations'),
        expansionPanelTitleDescription: [
          { title: fm('comorbidity.keyDiagnoses.title'), style: { fontWeight: 600 } },
        ].concat(
          ['m24.5', 'm40', 'm40.0', 'm40.1', 'm40.2', 'm41', 'm41.4', 'q65'].map((code) => {
            return { title: `${fm(`comorbidity.keyDiagnoses.${code}`)}` };
          }) as [],
        ),
        addons: [],
      },
      diagnosis: {
        id: 'diagnosis',
        type: 'single',
        name: fm('graph.diagnosis'),
        addons: [],
      },
      appointments: {
        id: 'appointments',
        type: 'single',
        name: fm('appointments.appointments'),
        addons: [],
      },
    },
  };
};

export default smaData;
