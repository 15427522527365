import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { take } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { titleCell } from './common/commonStyles';
import { IData } from './definitions';
import Icon from 'Components/_NewElements/Icon';
import { Container, Item } from 'Components/Grid';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { assertCapabilities } from 'Store/index';

const DataRow = styled('div')({
  '* > &:not(:last-of-type)': {
    paddingBottom: '1rem',
  },
});

const DataCellTitle = styled('div')(({ theme }: { theme?: Theme }) => ({
  ...titleCell(theme, false, false),
  fontSize: '1.4rem',
  lineHeight: '1.8rem',
  paddingBottom: '0.3rem',
}));

const DataCellName = styled('div')(({ theme }: { theme?: Theme }) => ({
  ...titleCell(theme, false, false),
  lineHeight: '1.6rem',
}));

const DataCellDates = styled('div')(({ theme }: { theme?: Theme }) => ({
  ...titleCell(theme, false, false),
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
}));

const DataEndingReasons = styled('div')(({ theme }: { theme?: Theme }) => ({
  ...titleCell(theme, false, false),
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
}));

const IntegrationStatus = styled(Container)(({ theme }: { theme?: Theme }) => ({
  height: '100%',
  borderTop: `0.1rem solid ${theme?.palette.grey[400]}`,
  marginTop: '1.5rem',
}));

const IntegrationStatusText = styled(Item)(({ theme }: { theme?: Theme }) => ({
  ...titleCell(theme, false, false),
  fontSize: '1.6rem',
  lineHeight: '1.6rem',
  fontWeight: 600,
}));

const MedicationTile = ({ data, capabilityGroups = {} }: IMedicationTile) => {
  const itemsCanFitAmount = 2;
  const shownItems = take(itemsCanFitAmount, data);

  const medicationIntegrationEnabled =
    assertCapabilities(['MIRANDA_MEDICATION_UPDATE'], capabilityGroups) ||
    assertCapabilities(['APOTTI_MEDICATION_UPDATE'], capabilityGroups);

  return (
    <Container direction={'column'}>
      <Item xs={true}>
        {shownItems.map((d) => (
          <React.Fragment key={d.key}>
            <DataRow>
              <DataCellTitle>{d.title}</DataCellTitle>
              <Container>
                <Item xs={1}>
                  <Icon icon={d.endDate ? 'stop' : 'play'} round color={d.endDate ? 'grayMedium' : 'appBlueMedium'} />
                </Item>
                <Item xs={true}>
                  <DataCellName style={{ maxWidth: '35rem' }} className="subtitle ellipsis">
                    {d.medicationName}
                  </DataCellName>
                  <DataCellDates>
                    {<span style={{ fontWeight: 600 }}>{d.date}</span>}
                    {!d.endDate && ` `}
                    {!d.endDate && <FormattedMessage id="medication.starting" />}
                    {d.endDate && ` – `}
                    {d.endDate && <span style={{ fontWeight: 600 }}>{d.endDate}</span>}
                  </DataCellDates>
                  <DataEndingReasons className="ellipsis-multiline" style={{ WebkitLineClamp: 2 }}>
                    {d.endReasons}
                  </DataEndingReasons>
                </Item>
              </Container>
            </DataRow>
          </React.Fragment>
        ))}
      </Item>
      <Item style={{ height: '4rem' }}>
        <IntegrationStatus alignItems={'center'}>
          {medicationIntegrationEnabled ? (
            <Item xs={1}>
              <Icon icon="mergeType" />
            </Item>
          ) : (
            <Item xs={1.1} style={{ marginLeft: '-0.32rem' }}>
              <Icon icon="sqicon" size={3.1} />
            </Item>
          )}

          <IntegrationStatusText>
            {medicationIntegrationEnabled ? (
              <FormattedMessage id="medication.integrationInUse" />
            ) : (
              <FormattedMessage id="medication.integrationNotInUse" />
            )}
          </IntegrationStatusText>
        </IntegrationStatus>
      </Item>
    </Container>
  );
};

interface IMedicationData {
  title: IData['title'];
  medicationName: IData['value'];
  date: IData['value'];
  endDate?: IData['endDate'];
  endReasons?: string[];
  key: IData['key'];
}

export interface IMedicationTile extends ICapabilityContextProps {
  data: Array<IMedicationData>;
}

export default withCapabilities(MedicationTile);
