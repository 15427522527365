import { INeuroCommit, INeuroDocument } from 'neuro-data-structures';
import { omit } from 'ramda';
import { getJWTData } from './jwtAuthTools';

const useruuid = getJWTData()?.useruuid;

export type TCommitControlProps = Pick<IControlProps, '_cid' | '_cdate' | '_editing' | '_commitsLength'>;

/**
 * Export data and control properties from latest selectable commit
 *
 * Note: Previously this required checking for user id, but document loading was changed so that
 *       unfinished commits from other users are filtered at loading time.
 * @param {INeuroCommit[]} commitArray - Array of commits
 * @return {object} Object of document data
 */
export const commitDataFromArray = <T = unknown>(commitArray: INeuroCommit[]): (TCommitControlProps & T) | null => {
  let currentCommit: INeuroCommit | null = null;

  for (const commit of commitArray) {
    // Set the processed commit as currentCommit if there is no currentCommit, the processed commit is unfinished or
    // the the currentCommit is finished before the processed commit.
    if (
      !currentCommit ||
      commit.commitDate === null ||
      (currentCommit.commitDate !== null && currentCommit.commitDate < commit.commitDate)
    ) {
      currentCommit = commit;
    }
  }

  if (!currentCommit) return null;

  return {
    ...currentCommit.data,
    _cid: currentCommit.commitId,
    _cdate: currentCommit.commitDate !== null ? currentCommit.commitDate : currentCommit.createDate,
    _commitCreator: currentCommit.creatorId,
    _editing: currentCommit.commitDate === null,
    _commitsLength: commitArray.length,
  };
};

/**
 * Return latest commit data
 * @param {INeuroDocument} doc - Document
 * @return {document} Document data with control props included
 */
export const mergeDocument = <T extends IControlProps>(doc: INeuroDocument): T & any => {
  // Remove unfinished commits from other users
  const filteredCommitArray = doc.commits.filter((c) => !(c.commitDate === null && c.creatorId !== useruuid));

  const handledCommits: (TCommitControlProps & IControlProps) | null = commitDataFromArray(filteredCommitArray);

  if (!handledCommits)
    return {
      _id: doc.documentId,
      _type: doc.documentType,
      _lockedFor: doc.lockedFor ?? undefined,
      _source: doc.source ?? undefined,
    };

  handledCommits._id = doc.documentId;
  handledCommits._type = doc.documentType;
  handledCommits._creator = doc.creatorId;
  handledCommits._ownerOrg = doc.ownerOrg;
  handledCommits._lockedFor = doc.lockedFor ?? undefined;
  handledCommits._source = doc.source ?? undefined;
  handledCommits._docCreateDate = doc.createDate;
  handledCommits._pastOwners = doc.pastOwners;
  return handledCommits;
};

/**
 * Get latest finished commit of all non-removed documents sorted by document
 * creation time (descending).
 *
 * @param {INeuroDocument[]} docArray - Array of documents
 * @return {document[]} Document data array with control props included
 */
export const sortAndMergeDocuments = <T extends IControlProps>(docArray: Array<INeuroDocument>): Array<T> => {
  if (!Array.isArray(docArray)) return [];
  return docArray
    .reduce<Array<T>>((acc, cur) => {
      if (!Number.isInteger(cur.removeTS)) {
        acc.push(mergeDocument(cur));
      }
      return acc;
    }, [])
    .sort((a: any, b: any) => b._docCreateDate - a._docCreateDate);
};

export const controlProps: Array<keyof IControlProps> = [
  '_id',
  '_type',
  '_cid',
  '_cdate',
  '_editing',
  '_lockedFor',
  '_creator',
  '_ownerOrg',
  '_source',
  '_commitsLength',
  '_docCreateDate',
  '_commitCreator',
  '_pastOwners',
];

/**
 * Remove control props from document data
 * @param  {{ [key: string]: any }} doc - Document with control props
 * @return {{ [key: string]: any }} Any document without control props
 */
export const omitControlProps = <T = any>(
  doc: T & Partial<IControlProps>,
): Omit<T & Partial<IControlProps>, keyof IControlProps> =>
  omit<T & Partial<IControlProps>, keyof IControlProps>(controlProps, doc);

/**
 * Get array of fields missing in a document
 * @param {Array<string>} fields list of fields wanted to check in the data
 * @param {IFormData['document']} docData Data in which the fields are checked to be found
 * @returns Array of the fields missing, being undefined or null
 */
export const getMissingFields = (fields: Array<string>, docData: IFormData['document']): Array<string> => {
  const missing: Array<string> = [];
  fields.forEach((f) => {
    if (!(f in docData) || docData[f] === undefined || docData[f] === null) {
      missing.push(f);
    }
  });
  return missing;
};
