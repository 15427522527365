import { filter, find, path } from 'ramda';
import { sortPartialDate } from 'neuro-utils';

export type TDocument = IPBAS;

export const sortAndTakeLatest = (type: string[], documents?: TDocument[]): TDocument[] => {
  if (documents) {
    return documents
      .sort((n1, n2) => n1._cdate - n2._cdate)
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()
      .filter((d) => type.some((t) => d._type === t), documents)
      .slice(0, 7);
  } else return [];
};

const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : undefined;

export const filterDocs = (type: string, documents: TDocument[]): TDocument[] =>
  documents ? filter((d) => d._type === type, documents) : [];

export const getHeaderId = (documents: Array<TDocument>, editing: any, viewing: any): string => {
  // handle both editing and viewing modes
  const docId = editing ? editing : viewing;
  // if the user is in editing or viewing mode and docType returns the doctype, return correct title for the header
  const dType = docType(documents, docId);
  if (dType) {
    return `behaviour.${dType}.${dType}`;
  }
  return 'behaviour.title';
};

export const isPbaSComplete = (doc: IPBAS): boolean => {
  if (!doc) return false;
  // Implement
  return true;
};

export const countPbaSScore = (doc: IPBAS): number => {
  if (!doc) return 0;
  // IMplement
  return 66;
};

/**
 * Function for getting graph design of pbas test
 * @param value value of a single field of pbas test
 * @returns the value related design used in graph
 */
export const getPbaSDesign = (
  value: string | number,
): { color: string; strokeColor?: string; strokeWidth?: number; textColor?: string } => {
  const config: { [key: string]: { color: string; strokeColor?: string; strokeWidth?: number; textColor?: string } } = {
    '0': {
      color: '#FDFECC',
      textColor: '#4E0027',
    },
    '1': {
      color: '#FDFECC',
      textColor: '#4E0027',
    },
    '2': {
      color: '#FCF2B3',
      textColor: '#4E0027',
    },
    '3': {
      color: '#F8DB99',
      textColor: '#4E0027',
    },
    '4': {
      color: '#F3BE80',
      textColor: '#4E0031',
    },
    '5': {
      color: '#EC9B66',
      textColor: '#4E0031',
    },
    '6': {
      color: '#E4734D',
      textColor: '#FFFFFF',
    },
    '7': {
      color: '#DA4933',
      textColor: '#FFFFFF',
    },
    '8': {
      color: '#CF1C1A',
      textColor: '#FFFFFF',
    },
    '9': {
      color: '#C20012',
      textColor: '#FFFFFF',
    },
    '10': {
      color: '#B2001A',
      textColor: '#FFFFFF',
    },
    '11': {
      color: '#A20021',
      textColor: '#FFFFFF',
    },
    '12': {
      color: '#910026',
      textColor: '#FFFFFF',
    },
    '13': {
      color: '#810029',
      textColor: '#FFFFFF',
    },
    '14': {
      color: '#70002A',
      textColor: '#FFFFFF',
    },
    '15': {
      color: '#5F0029',
      textColor: '#FFFFFF',
    },
    '16': {
      color: '#4E0027',
      textColor: '#FFFFFF',
    },
    'N/A': {
      color: '#FFFFFF',
      strokeColor: '#6A6A6A',
      strokeWidth: 0.5,
      textColor: '#6A6A6A',
    },
    '-': {
      color: '#FFFFFF',
      strokeColor: '#6A6A6A',
      strokeWidth: 0.5,
      textColor: '#6A6A6A',
    },
  };
  if (!(value in config)) return { color: 'FFFFFF', strokeColor: '#6A6A6A', strokeWidth: 0.5, textColor: '#6A6A6A' };
  return typeof value === 'string' ? config[value] : config[value.toString()];
};
