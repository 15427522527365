export const steps = [
  'talking',
  'chewing',
  'swallowing',
  'breathing',
  'impairmentOfAbilityToBrushTeethOrCombHair',
  'impairmentOfAbilityToAriseFromAChair',
  'doubleVision',
  'eyelidDroop',
];

export const getStepsLocalesGetter = (fm: (s: string) => string) => (step: string | number) =>
  fm('functionalPerformanceMgravis.labels.mgadl.' + step);

export const getStepContent =
  (fm: (s: string) => string) =>
  (
    stepName: string,
  ): {
    opts: Array<number | string>;
    optsLocale: (o: string | number, i?: number) => string;
    info?: string;
    assets?: number[];
    height?: number;
    instruction?: JSX.Element;
    treatValuesAsNumbers?: true;
  } => {
    switch (stepName) {
      default: {
        return {
          opts: [0, 1, 2, 3],
          optsLocale: (o: string | number) => fm('functionalPerformanceMgravis.opts.mgadl.' + stepName + '.' + o),
        };
      }
    }
  };
