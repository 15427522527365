import { path, find, filter } from 'ramda';

const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : undefined;

export const filterDocs = (type: string, documents: TDocument[]): TDocument[] =>
  documents ? filter((d) => d._type === type, documents) : [];

export type TDocument =
  | ISleepPolygraphy
  | ISleepStudy
  | ISpirometry
  | INightOximetry
  | ICarbonDioxideMeasurement
  // For MADtherapy question in sleep polygraphy
  | IMADTherapy
  // For filtering shown tests for specific diagnosis
  | IDiagnosis
  | IDiagnosticExamination; // General docType for different CNP examinations

export const getHeaderId = (
  documents: Array<TDocument>,
  editing: any,
  viewing: any,
  diagnosticExamKey?: TDiagnosticExamKeys,
  platform?: Platform,
): string => {
  if (platform === 'mgravis') {
    if (docType(documents, editing ?? viewing) === 'diagnosticExamination' && diagnosticExamKey) {
      return `clpAndCnpTests.mgravis.${diagnosticExamCodes[diagnosticExamKey]?.code}`;
    }
    return 'clpAndCnpTests.mgravis.title';
  }
  return docType(documents, editing ?? viewing) === 'sleepPolygraphy'
    ? 'clpAndCnpTests.sleepPolygraphy'
    : docType(documents, editing ?? viewing) === 'sleepStudy'
      ? 'clpAndCnpTests.sleepStudy'
      : docType(documents, editing ?? viewing) === 'spirometry'
        ? 'clpAndCnpTests.spirometry'
        : docType(documents, editing ?? viewing) === 'nightOximetry'
          ? 'clpAndCnpTests.nightOximetry'
          : docType(documents, editing ?? viewing) === 'carbonDioxideMeasurement'
            ? 'clpAndCnpTests.carbonDioxideMeasurement'
            : 'clpAndCnpTests.title';
};

export const diagnosticExamCodes: Partial<Record<TDiagnosticExamKeys, ICode>> = {
  enmg: {
    codeSystem: 'THL - Toimenpideluokitus',
    code: 'XA402',
    display: 'ENMG',
  },
  repeatedNerveStimulation: {
    codeSystem: 'http://snomed.info/sct',
    code: '252748005',
    display: 'Toistostimulaatio',
  },
  singleFiberEMG: {
    codeSystem: 'http://snomed.info/sct',
    code: '252756008',
    display: 'Yksisyy-EMG',
  },
};
