import { allDiagnoses } from 'Routes/Diagnosis/utils/definitions';
import { TMsATCCode, atcCodesByPlatform } from 'Routes/Medication/Document/config';
import { INeuroDocument } from 'neuro-data-structures';
import { partialDateToValue } from 'neuro-utils';
import { MIRANDA_MED_INTG_SOURCE } from '../../../config/generalSettings';
import { fieldNameToCodeString } from 'Routes/Background/utils';

/**
 * Filter usable documents for the user. Deleted or documents with no finished commits and not created by current user should not be returned
 * @param {Pick<IJWTBody, 'useruuid'>} jwt - JWT
 * @param {INeuroDocument[]} documents - INeuroDocument[]
 * @returns {boolean} Whether to keep document
 */
export const filterUsableDocuments = (
  jwt: Pick<IJWTBody, 'useruuid'>,
  documents: INeuroDocument[],
): INeuroDocument[] => {
  const filter = (doc: INeuroDocument): boolean => {
    if (doc.removeTS !== null) return false; // If document is deleted, discard it
    // If document has zero finished commits or it is not created by current user, discard it (we do not want to show empty documents)
    return doc.commits.some((c) => c.commitDate !== null) || doc.creatorId === jwt.useruuid;
  };
  return documents.filter(filter);
};

/**
 * Filter unfinished commits made by other users
 * @param {Pick<IJWTBody, 'useruuid'>} jwt - JWT
 * @param {INeuroDocument[]} documents - INeuroDocuments array
 * @returns {INeuroDocument[]} Filtered documents
 */
export const filterCommits = (jwt: Pick<IJWTBody, 'useruuid'>, documents: INeuroDocument[]): INeuroDocument[] => {
  // Filter away unclosed commits by other users
  const docsWithFilteredCommits = documents
    .map((d) => ({
      ...d,
      commits: d.commits.filter((c) => (!c.commitDate ? c.creatorId === jwt.useruuid : true)),
    }))
    .filter((d) => d.commits.length > 0);

  return docsWithFilteredCommits;
};

/**
 * Filter diagnosis documents
 * All platforms: Filter if diagnosis code not included in allDiagnoses list
 * @param {INeuroDocument[]} documents - INeuroDocuments array
 * @returns {INeuroDocument[]} Filtered documents
 */
export const filterDiagnoses = <T extends IControlProps>(
  documents: INeuroDocument[],
  sortedAndMergedDocuments: Array<T & any>,
): INeuroDocument[] => {
  // Filter unwanted documents, such as ones from integration that are irrelevant
  const filteredDocuments = documents.filter((d) => {
    if (d.documentType === 'diagnosis') {
      const mergedDocument: IDiagnosis = sortedAndMergedDocuments.find((s) => s._id === d.documentId);
      // Filter diagnosis documents containing unsupported diagnosis code
      if (mergedDocument.diagnosis && !allDiagnoses.includes(mergedDocument.diagnosis)) {
        return false;
      }
      return true;
    }
    return true;
  });
  return filteredDocuments;
};

const msATCCodes = atcCodesByPlatform['ms'];
const valueIsMsATCCode = (atc: unknown): atc is TMsATCCode => typeof atc === 'string' && msATCCodes.includes(atc);

/**
 * Compare two MS ATC codes. Interprets codes that map to the same medication (name) as equal
 * @param {TMsATCCode} atc1 - First ATC code to compare
 * @param {TMsATCCode} atc2 - Second ATC code to compare
 * @returns {boolean} - true if codes are equal, false if not
 */
export const compareMsATCCodes = (atc1?: TMsATCCode, atc2?: TMsATCCode): boolean => {
  // Map for cases where multiple ATC codes map to the same medication (name)
  const atcCodeToMedicationNameMap = new Map<TMsATCCode, string>([
    // gilenya
    ['L04AA27', 'GILENYA'],
    ['L04AE01', 'GILENYA'],
    // tysabri
    ['L04AA23', 'TYSABRI'],
    ['L04AG03', 'TYSABRI'],
    // lemtrada
    ['L04AA34', 'LEMTRADA'],
    ['L04AG06', 'LEMTRADA'],
    // aubagio
    ['L04AA31', 'AUBAGIO'],
    ['L04AK02', 'AUBAGIO'],
    // mayzent
    ['L04AA42', 'MAYZENT'],
    ['L04AE03', 'MAYZENT'],
    // ocrevus
    ['L04AA36', 'OCREVUS'],
    ['L04AG08', 'OCREVUS'],
    // mabhtera
    ['L01XC02', 'MABHTERA'],
    ['L01FA01', 'MABHTERA'],
    // ofatumumab
    ['L01XC10', 'OFATUMUMAB'],
    ['L04AA52', 'OFATUMUMAB'],
    ['L01FA02', 'OFATUMUMAB'],
    ['L04AG12', 'OFATUMUMAB'],
    // zeposia
    ['L04AA38', 'ZEPOSIA'],
    ['L04AE02', 'ZEPOSIA'],
  ]);

  // Return false if either ATC code is not a MS ATC code
  if (!valueIsMsATCCode(atc1) || !valueIsMsATCCode(atc2)) return false;

  // Return true if both ATC codes are equal
  if (atc1 === atc2) return true;

  // Return true if both ATC codes map to the same medication name
  const medicationName1 = atcCodeToMedicationNameMap.get(atc1);
  const medicationName2 = atcCodeToMedicationNameMap.get(atc2);
  if (medicationName1 && medicationName2 && medicationName1 === medicationName2) return true;

  return false;
};

/**
 * Filter medication documents
 * MS: Filter if the medication has MS ATC code, is not filled manually and dates overlap with a manually entered medication with the same name
 * @param {INeuroDocument[]} documents - INeuroDocuments array
 * @returns {INeuroDocument[]} Filtered documents
 */
export const filterMedications = <T extends IControlProps>(
  docs: INeuroDocument[],
  sortedAndMergedDocuments: Array<T & any>,
): INeuroDocument[] => {
  const returnedDocuments = docs.filter((d) => d.documentType !== 'medication');
  const medications = docs.filter((d) => d.documentType === 'medication');

  if (medications.length === 0) return returnedDocuments;

  const mergedMedications = sortedAndMergedDocuments.filter((d) => d._type === 'medication') as IMedication[];

  // Manually entered medications that have an MS medication ATC code
  const filterableMedications = mergedMedications.filter(
    (m) => m._source !== MIRANDA_MED_INTG_SOURCE && valueIsMsATCCode(m.atc),
  );

  const medicationFilter = (med: INeuroDocument): boolean => {
    if (med.source !== MIRANDA_MED_INTG_SOURCE) return true; // Filled manually
    const thisMedication = mergedMedications.find((m) => m._id === med.documentId);

    const filteringMeds = filterableMedications.filter(
      // Type check for filterableMedications is already done in the filter
      (f) => valueIsMsATCCode(thisMedication?.atc) && compareMsATCCodes(f.atc as TMsATCCode, thisMedication?.atc),
    );
    if (!thisMedication || filteringMeds.length === 0) return true; // Not included in duplicates

    const shouldNotBeFiltered = filteringMeds.every((filter) => {
      if (!thisMedication.hasEnded?.[0] && !filter.hasEnded?.[0]) {
        // Medication has not ended and Filter not ended either
        return false;
      } else {
        const filterStart = partialDateToValue(filter.startDate);
        const filterEnd = filter.endDate ? partialDateToValue(filter.endDate) : Date.now();
        const medStart = partialDateToValue(thisMedication.startDate);
        const medEnd = partialDateToValue(thisMedication.endDate);
        if (medStart > filterStart && medStart < filterEnd) return false; // Medication start overlaps with filter
        if (medEnd > filterStart && medEnd < filterEnd) return false; // Medication end overlaps with filter
        if (medStart <= filterStart && medEnd >= filterEnd) return false; // Whole medication (being longer) overlaps with filter
        //if (medStart >= filterStart && medEnd <= filterEnd) return false; // Whole medication (being shorter) overlaps with filter
        return true;
      }
    });

    return shouldNotBeFiltered;
  };

  return [...returnedDocuments, ...medications.filter(medicationFilter)];
};

/**
 * Filter measurement documents
 * All platforms: Filter if measurement values coming from integration overlap with locally/manually entered ones
 * @param {INeuroDocument[]} documents - INeuroDocuments array
 * @returns {INeuroDocument[]} Filtered documents
 */
export const filterMeasurements = <T extends IControlProps>(
  docs: INeuroDocument[],
  sortedAndMergedDocuments: Array<T & any>,
): INeuroDocument[] => {
  const returnedDocuments = docs.filter((d) => d.documentType !== 'measurement');
  const measurements = docs.filter((d) => d.documentType === 'measurement');

  if (measurements.length === 0) return returnedDocuments;

  const mergedMeasurements = sortedAndMergedDocuments.filter((d) => d._type === 'measurement') as IMeasurement[];

  const measurementFilter = (measurement: INeuroDocument): boolean => {
    const thisMeasurement = mergedMeasurements.find((m) => m._id === measurement.documentId);

    if (!thisMeasurement) return true;

    const typeAndDateComparison = (d: IMeasurement) =>
      partialDateToValue(d.date) === partialDateToValue(thisMeasurement.date) &&
      fieldNameToCodeString(d.code ?? '') === fieldNameToCodeString(thisMeasurement.code ?? '');

    return !thisMeasurement._lockedFor || !mergedMeasurements.some((d) => typeAndDateComparison(d) && !d._lockedFor);
  };

  return [...returnedDocuments, ...measurements.filter(measurementFilter)];
};

const includedLForceDocTypes = ['LAU', 'UTL']; // Lausuttu, ulkoinen lausunto

/**
 * Filter L-Force documents
 * @param {INeuroDocument[]} documents - INeuroDocuments array
 * @returns {INeuroDocument[]} Filtered documents
 */
export const filterLForceDocs = <T extends IControlProps>(
  documents: INeuroDocument[],
  sortedAndMergedDocuments: Array<T & any>,
): INeuroDocument[] => {
  const filteredDocuments = documents.filter((d) => {
    if (d.source === 'l-force-integration') {
      const mergedDocument: { studyStatus: string } = sortedAndMergedDocuments.find((s) => s._id === d.documentId);
      // Only include wanted L-Force doc types
      if (includedLForceDocTypes.includes(mergedDocument.studyStatus)) {
        return true;
      }
      return false;
    }
    return true;
  });
  return filteredDocuments;
};
